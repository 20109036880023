import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CgMenuRight, CgMenuLeft } from "react-icons/cg";
import { FiHome, FiSearch } from "react-icons/fi";
import logo from "./../../assets/images/logo.png";
import { MdCardMembership, MdOutlineDiscount } from "react-icons/md";
import { BsBagCheck } from "react-icons/bs";

import { CartContext } from "../../context/CartContext";
import { SidebarContext } from "../../context/SidebarContext";
import { CardProductComponent } from "./component/card-product-component";
import { GET_TENANTS } from "src/graphql/query/Tenant";
import { GET_SUBMENUS } from "src/graphql/query/Submenu";
import { LoadingComponent } from "../../components/loading/loading-component";
import { ErrorComponent } from "../../components/error/error-component";
import { useQuery } from "@apollo/client";
import { MenuComponent } from "./component/menu-component";
import { GET_PRODUCTS } from "src/graphql/query/Product";
import { PopUpProductComponent } from "./component/PopUpProductComponent";
import { ErrorServices } from "src/service/ErrorServices";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { LanguageContext } from "../../context/LanguageContext";
import language from "../../language/data.json";
import { OperatorContext } from "../../context/OperatorContext";
import { PopUpAdvertiseComponent } from "./component/PopUpAdvertiseComponent";
import { CheckMembership } from "./check-membership";

export function MenuPage() {
  const navigate = useNavigate();
  const errorServices = new ErrorServices();

  const { cart, setCart } = useContext(CartContext);
  const { sidebar, setSidebar } = useContext(SidebarContext);
  const { lang } = useContext(LanguageContext);
  const { operator } = useContext(OperatorContext);

  const {
    loading: loadingTenants,
    error: errorTenants,
    data: dataTenants,
  } = useQuery(GET_TENANTS);

  const [menuActive, setMenuActive] = useState(
    dataTenants?.tenants[0]?.menus[0]?.id
  );

  const {
    loading: loadingSubmenus,
    error: errorSubmenus,
    data: dataSubmenus,
    refetch: refetchSubmenus,
  } = useQuery(GET_SUBMENUS, { variables: { menuId: menuActive } });

  const [subMenuActive, setSubMenuActive] = useState(
    dataSubmenus?.submenus[0]?.id
  );

  const {
    loading,
    error,
    data: dataProducts,
  } = useQuery(GET_PRODUCTS, { variables: { submenuId: subMenuActive } });

  const [productActive, setProductActive] = useState(null);
  const [showAdvertise, setShowAdvertise] = useState(false);

  useEffect(() => {
    if (false) {
      setMenuActive(dataTenants?.tenants[0]?.menus[0]?.id);
    } else {
      setMenuActive("scan");
    }
  }, [dataTenants]);

  useEffect(() => {
    setSubMenuActive(dataSubmenus?.submenus[0]?.id);
  }, [dataSubmenus]);

  if (loadingTenants) return <LoadingComponent />;
  if (errorTenants) {
    errorServices.Create({
      message: errorTenants.message,
    });
    return <ErrorComponent />;
  }

  return (
    <div className="w-full h-screen grid grid-cols-12">
      {showAdvertise ? (
        <PopUpAdvertiseComponent
          handleClose={() => {
            setShowAdvertise(false);
          }}
        />
      ) : null}
      <div
        className={`bg-black duration-300 flex flex-col h-screen ${
          sidebar ? "col-span-2" : "hidden"
        }`}
      >
        <div className="flex justify-center items-center lg:mt-20 mt-10">
          <button
            className="text-white"
            onClick={() => {
              setSidebar(false);
            }}
          >
            <CgMenuRight size={72} />
          </button>
        </div>

        <div
          className="flex w-full h-fit overflow-y-scroll items-center lg:gap-28 gap-16 lg:mt-24 mt-14 font-semibold text-white rotate-180"
          style={{
            writingMode: "vertical-rl",
          }}
        >
          {dataTenants.tenants.toReversed().map((tenant, index) => {
            return tenant.menus.toReversed().map((menu, i) => {
              if (menu.isPromotion == false || menu.isPromotion == true)
                return (
                  <MenuComponent
                    key={menu.id}
                    id={menu.id}
                    active={menuActive == menu.id}
                    name={menu.name}
                    handleClick={(id) => {
                      setMenuActive(id);
                    }}
                  />
                );
            });
          })}

          <MenuComponent
            key={"scan"}
            id={"scan"}
            active={menuActive == "scan"}
            name={"Scan"}
            handleClick={() => {
              setMenuActive("scan");
            }}
          />
        </div>

        <div className="mt-auto lg:flex hidden justify-center items-center my-10 py-10">
          {operator?.userRole == "manager" ? (
            <button
              className="text-white"
              onClick={() => {
                navigate("/member");
              }}
            >
              <MdCardMembership size={72} />
            </button>
          ) : null}
        </div>
      </div>

      <div
        className={`flex flex-col items-center h-screen ${
          sidebar ? "col-span-10" : "col-span-12"
        }`}
      >
        <div className="heading w-full shadow-lg px-10 lg:pb-10 pb-3">
          <div className="flex justify-between lg:mt-20 mt-4 w-full">
            {sidebar ? null : (
              <button
                className="lg:p-4"
                onClick={() => {
                  setSidebar(true);
                }}
              >
                <CgMenuLeft size={48} className="lg:scale-150" />
              </button>
            )}
            <img src={logo} className="lg:w-[180px] w-[100px]" />
            <button
              className="lg:p-4"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <FiHome size={48} className="lg:scale-150" />
            </button>
          </div>

          <div className="w-full mt-5 lg:block hidden">
            <h2 className="font-bold text-[48px]">
              <span className="text-main">{language.menu.find[lang]}</span>
            </h2>
          </div>

          <div className="w-full lg:mt-8 mt-4">
            <div className="flex gap-16 overflow-x-scroll">
              {dataSubmenus &&
                dataSubmenus.submenus.map((submenu, index) => {
                  return (
                    <div
                      key={submenu.id}
                      className="flex flex-col items-center"
                      onClick={() => {
                        setSubMenuActive(submenu.id);
                      }}
                    >
                      <h4 className="font-bold lg:text-[28px] text-[16px] text-center leading-tight">
                        {submenu.name}
                      </h4>
                      <div
                        className={`h-1.5 bg-main mt-2 duration-300 ${
                          subMenuActive == submenu.id ? "w-16" : "w-0"
                        }`}
                      ></div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {menuActive == "scan" ? (
          <CheckMembership />
        ) : (
          <div className="body-product grow overflow-scroll px-10 w-full pb-10">
            {productActive != null ? (
              <PopUpProductComponent
                product={productActive}
                handleClose={() => {
                  setProductActive(null);
                }}
                callback={({ count, notes }) => {
                  const data = {
                    ...productActive,
                    amount: count,
                    notes: notes,
                    menuId: menuActive,
                    buy: true,
                  };
                  setCart([...cart, data]);
                  setProductActive(null);
                }}
              />
            ) : null}

            {loading ? (
              <div className="h-fit mt-10">
                <LoadingComponent />{" "}
              </div>
            ) : (
              <div
                className={`w-full mt-10 overflow-hidden relative grid lg:gap-8 ${
                  sidebar ? "lg:grid-cols-2 grid-cols-3" : "grid-cols-3"
                }`}
              >
                {dataProducts?.products.map((product, index) => {
                  if (
                    operator?.userRole == "tenant" &&
                    product.name.includes("[HIDDEN_PRODUCT]-") == true
                  )
                    return null;
                  return (
                    <CardProductComponent
                      key={product.id}
                      item={{
                        id: product.id,
                        timeForId: new Date().getTime(),
                        image: product.displayPic,
                        name: product.name,
                        desc: product.desc,
                        price: product.price,
                        originalPrice: product.originalPrice,
                      }}
                      handleDetail={(timeForId) => {
                        setProductActive({ ...product, timeForId: timeForId });
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}

        <div className="w-full bg-white shadow-[0_35px_60px_15px_rgba(0,0,0,0.2)] lg:p-8 p-5 flex justify-center">
          <button
            onClick={() => {
              navigate(`/order-list`);
            }}
            className="flex items-center gap-5 bg-black lg:p-5 p-3 rounded-2xl w-full"
          >
            <div className="bg-main lg:w-16 w-12 lg:h-16 h-12 lg:p-4 flex justify-center items-center rounded-lg">
              <BsBagCheck size={24} className="lg:scale-150" />
            </div>
            <h2 className="lg:text-[36px] text-[24px] text-white">
              {cart.length == 0
                ? language.menu.empty[lang]
                : `
              Total ${rupiahFormat(
                cart.reduce(
                  (temp, x) => temp + x.originalPrice * x.amount,
                  0,
                  0
                )
              )} 
                | Item: ${cart.reduce((temp, x) => temp + x.amount, 0)}`}
            </h2>
          </button>
        </div>
      </div>
    </div>
  );
}
