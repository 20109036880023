import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CgMenuRight, CgMenuLeft } from "react-icons/cg";
import { FiHome, FiSearch } from "react-icons/fi";
import logo from "./../../assets/images/logo.png";
import { MdOutlineDiscount } from "react-icons/md";
import { BsBagCheck } from "react-icons/bs";

import { CartContext } from "../../context/CartContext";
import { SidebarContext } from "../../context/SidebarContext";
import { CardProductComponent } from "./component/card-product-component";
import { LoadingComponent } from "../../components/loading/loading-component";
import { ErrorComponent } from "../../components/error/error-component";
import { MenuComponent } from "./component/menu-component";
import { PopUpProductComponent } from "./component/popup-product-component";
import { ErrorServices } from "src/service/ErrorServices";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { LanguageContext } from "../../context/LanguageContext";
import language from "../../language/data.json";
import { PopUpAdvertiseComponent } from "./component/popup-advertise-component";
import { UserContext } from "../../context/UserContext";
import { TenantGraphServices } from "src/graphql/services/TenantGraphServices";
import { SubmenuGraphServices } from "src/graphql/services/SubmenuGraphServices";
import { ProductGraphServices } from "src/graphql/services/ProductGraphServices";
import { Tenant, Menu, Submenu, Product, ProductCart } from "src/types/init";

export function MenuPage() {
  const navigate = useNavigate();
  const { typeService } = useParams();

  const errorServices = new ErrorServices();

  const { cart, addToCart } = useContext(CartContext);
  const { sidebar, setSidebar } = useContext(SidebarContext);
  const { lang } = useContext(LanguageContext);
  const { user } = useContext(UserContext);

  const tenantGraphServices = new TenantGraphServices();
  const submenuGraphServices = new SubmenuGraphServices();
  const productGraphServices = new ProductGraphServices();

  const [menus, setMenus] = useState<Menu[]>([]);
  const [submenus, setSubmenus] = useState<Submenu[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  const [showAdvertise, setShowAdvertise] = useState(false);
  const [menuActive, setMenuActive] = useState("");
  const [subMenuActive, setSubMenuActive] = useState("");
  const [productActive, setProductActive] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const resTenants = await tenantGraphServices.gets();

    let tempMenus: Menu[] = [];
    resTenants.tenants.map((tenant: Tenant, index: number) => {
      tenant.menus.map((menu: Menu, i: number) => {
        tempMenus.push(menu);
      });
    });

    setMenus(tempMenus);

    fetchSubmenus({ menuId: tempMenus[0].id });
    setMenuActive(tempMenus[0].id);
  }

  async function fetchSubmenus({ menuId }: { menuId: string }) {
    const resSubmenus = await submenuGraphServices.gets({ menuId });

    setSubmenus(resSubmenus.submenus);
    fetchProducts({ submenuId: resSubmenus.submenus[0].id });
    setSubMenuActive(resSubmenus.submenus[0].id);
  }

  async function fetchProducts({ submenuId }: { submenuId: string }) {
    const resProducts = await productGraphServices.gets({ submenuId });

    setProducts(resProducts.products);
    setLoading(false);
  }

  // return <> </>;

  // if (loadingTenants) return <LoadingComponent />;
  // if (errorTenants) {
  //   errorServices.Create({
  //     message: errorTenants.message,
  //   });
  //   return <ErrorComponent />;
  // }

  return (
    <div className="w-full h-screen grid grid-cols-12">
      {showAdvertise ? (
        <PopUpAdvertiseComponent
          handleClose={() => {
            setShowAdvertise(false);
          }}
        />
      ) : null}
      <div
        className={`bg-black duration-300 flex flex-col h-screen ${
          sidebar ? "col-span-2" : "hidden"
        }`}
      >
        <div className="flex justify-center items-center lg:mt-20 mt-10">
          <button
            className="text-white"
            onClick={() => {
              setSidebar(false);
            }}
          >
            <CgMenuRight size={72} />
          </button>
        </div>

        <div
          className="flex w-full h-fit overflow-y-scroll items-center lg:gap-28 gap-16 lg:mt-24 mt-14 font-semibold text-white rotate-180"
          style={{
            writingMode: "vertical-rl",
          }}
        >
          {menus.map((menu: Menu, index) => {
            if (
              menu.name.includes("[ONLINE]") == true &&
              typeService == "dine-in"
            )
              return null;
            if (
              menu.name.includes("[ONLINE]") == true &&
              typeService == "take-away"
            )
              return null;
            if (
              menu.name.includes("[ONLINE]") == false &&
              typeService == "online"
            )
              return null;

            return (
              <MenuComponent
                key={menu.id}
                menu={menu}
                active={menuActive == menu.id}
                handleClick={(id) => {
                  setMenuActive(id);
                  fetchSubmenus({ menuId: id });
                }}
              />
            );
          })}
        </div>

        <div className="mt-auto lg:flex hidden justify-center items-center my-10 py-10">
          <button className="text-white">
            <MdOutlineDiscount size={72} />
          </button>
        </div>
      </div>

      <div
        className={`flex flex-col items-center h-screen ${
          sidebar ? "col-span-10" : "col-span-12"
        }`}
      >
        <div className="heading w-full shadow-lg px-10 lg:pb-10 pb-3">
          <div className="flex justify-between lg:mt-20 mt-4 w-full">
            {sidebar ? null : (
              <button
                className="lg:p-4"
                onClick={() => {
                  setSidebar(true);
                }}
              >
                <CgMenuLeft size={48} className="lg:scale-150" />
              </button>
            )}
            <img src={logo} className="lg:w-[180px] w-[100px]" />
            <button
              className="lg:p-4"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <FiHome size={48} className="lg:scale-150" />
            </button>
          </div>

          <div className="w-full mt-5 lg:block hidden">
            <h2 className="font-bold text-[48px]">
              <span className="text-main">{language.menu.find[lang]}</span>
            </h2>
          </div>

          <div className="w-full lg:mt-8 mt-4">
            <div className="flex gap-16 overflow-x-scroll">
              {submenus.map((submenu: Submenu, index) => {
                if (
                  submenu.name.includes("[ONLINE]") == true &&
                  typeService == "dine-in"
                )
                  return null;
                if (
                  submenu.name.includes("[ONLINE]") == true &&
                  typeService == "take-away"
                )
                  return null;
                if (
                  submenu.name.includes("[ONLINE]") == false &&
                  typeService == "online"
                )
                  return null;

                return (
                  <div
                    key={submenu.id}
                    className="flex flex-col items-center"
                    onClick={() => {
                      setSubMenuActive(submenu.id);
                      fetchProducts({ submenuId: submenu.id });
                    }}
                  >
                    <h4 className="font-bold lg:text-[28px] text-[16px] text-center leading-tight">
                      {submenu.name}
                    </h4>
                    <div
                      className={`h-1.5 bg-main mt-2 duration-300 ${
                        subMenuActive == submenu.id ? "w-16" : "w-0"
                      }`}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="body-product grow overflow-scroll px-10 w-full pb-10">
          {productActive != null ? (
            <PopUpProductComponent
              product={productActive}
              handleClose={() => {
                setProductActive(null);
              }}
              callback={({
                count,
                note,
                variant,
              }: {
                count: number;
                note: string;
                variant: string;
              }) => {
                const data = {
                  id: new Date().getTime().toString(),
                  product: productActive,
                  qty: count,
                  note: note,
                  buy: true,
                  variant: variant,
                } as ProductCart;
                addToCart(data);
                setProductActive(null);
              }}
            />
          ) : null}

          {loading ? (
            <div className="h-fit mt-10">
              <LoadingComponent />{" "}
            </div>
          ) : (
            <div
              className={`w-full mt-10 overflow-hidden relative grid lg:gap-8 ${
                sidebar ? "lg:grid-cols-2 grid-cols-3" : "grid-cols-3"
              }`}
            >
              {products.map((product, index) => {
                if (
                  user.userRole == "tenant" &&
                  product.name.includes("[HIDDEN_PRODUCT]-") == true
                )
                  return null;

                if (
                  product.name.includes("[ONLINE]") == true &&
                  typeService == "dine-in"
                )
                  return null;
                if (
                  product.name.includes("[ONLINE]") == true &&
                  typeService == "take-away"
                )
                  return null;

                if (
                  product.name.includes("[ONLINE]") == false &&
                  typeService == "online"
                )
                  return null;

                return (
                  <CardProductComponent
                    key={product.id}
                    product={product}
                    handleDetail={(id) => {
                      setProductActive(product);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>

        <div className="w-full bg-white shadow-[0_35px_60px_15px_rgba(0,0,0,0.2)] lg:p-8 p-5 flex justify-center">
          <button
            onClick={() => {
              navigate(`/${typeService}/order-list`);
            }}
            className="flex items-center gap-5 bg-black lg:p-5 p-3 rounded-2xl w-full"
          >
            <div className="bg-main lg:w-16 w-12 lg:h-16 h-12 lg:p-4 flex justify-center items-center rounded-lg">
              <BsBagCheck size={24} className="lg:scale-150" />
            </div>
            <h2 className="lg:text-[36px] text-[24px] text-white">
              {cart.products.length == 0
                ? language.menu.empty[lang]
                : `
              Total ${rupiahFormat(
                cart.products.reduce((acc, item) => {
                  return (
                    acc +
                    (item.product.originalPrice +
                      (item.product.variants?.find(
                        (variant) => variant.id == item.variant
                      )?.originalPrice ?? 0)) *
                      item.qty
                  );
                }, 0)
              )}
                `}
            </h2>
          </button>
        </div>
      </div>
    </div>
  );
}
