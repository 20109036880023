import {BrowserRouter, Route, Routes, Navigate, useSearchParams} from "react-router-dom";
import QR45CoffeeRoutes from "./routes";

export default function App45CoffeeQR() {
  return (
    <BrowserRouter>
      <QR45CoffeeRoutes />
    </BrowserRouter>
  );
}
