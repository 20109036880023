import { useContext, useState } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import language from "../../../language/data.json";
import { MembersServices } from "src/service/MembersServices";

export function MemberSection({ handleCancel, callback }) {
  const membersServices = new MembersServices();

  const { lang } = useContext(LanguageContext);
  const [idMember, setIdMember] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    if (idMember != "") {
      const res = await membersServices.getMemberCard({ id: idMember });
      if (res) {
        callback(res);
      } else {
        alert("Member not found");
      }
    } else {
      alert("Please enter member id");
    }
  }

  return (
    <div className="bg-slate-600 bg-opacity-60 fixed w-full h-full flex justify-center items-center">
      <div className="rounded-3xl shadow-x bg-white">
        <form
          onSubmit={handleSubmit}
          className="flex-col text-center py-8 px-8 flex"
        >
          <h2 className="lg:text-[36px] text-[28px] font-bold mb-2">
            Card Membership
          </h2>
          <div className="flex justify-center my-4">
            <input
              className="w-full rounded-lg bg-white outline-none border border-slate-200 p-3 text-[24px] text-center"
              placeholder={"1234 5678 9101 1121"}
              type="number"
              onChange={(e) => {
                setIdMember(e.target.value);
              }}
              value={idMember}
            />
          </div>

          <div className="w-full flex gap-5 mt-2">
            <button
              onClick={() => {
                handleCancel();
              }}
              type="button"
              className="lg:text-[24px] text-[20px] w-full font-bold bg-secondary text-white hover:bg-main hover:text-white py-3 rounded-2xl shadow-xl"
            >
              Not Member
            </button>
            <button
              type="submit"
              className="lg:text-[24px] text-[20px] w-full font-bold bg-main text-white hover:bg-secondary hover:text-white py-3 rounded-2xl shadow-xl"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
