import { createContext, useState } from "react";

const _cartNull = [];

const _cart = [
  {
    id: 35,
    name: "Nasi Goreng",
    price: 1,
    displayPic: "https://picsum.photos/200",
    amount: 1,
    notes: "-",
    menuId: 5,
    buy: true,
  },
];

export const CartContext = createContext();

export const CartProvider = (props) => {
  const [cart, setCart] = useState(_cartNull);

  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {props.children}
    </CartContext.Provider>
  );
};
