import { useQuery } from "@apollo/client";
import { useContext, useState } from "react";
import { FiChevronLeft, FiMinus, FiPlus, FiShoppingCart } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import imgProduct1 from "./../../assets/images/banner/1.jpg";
import { ErrorComponent } from "./../../components/error/error-component";
import { LoadingComponent } from "./../../components/loading/loading-component";
import { CartContext } from "./../../context/CartContext";
import { GET_PRODUCT } from "src/graphql/query/Product";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function MenuDetailPage() {
  const navigate = useNavigate();
  const { id, idTable } = useParams();

  const {
    loading,
    error,
    data: dataProduct,
  } = useQuery(GET_PRODUCT, { variables: { productId: id } });

  const { cart, setCart } = useContext(CartContext);

  const [count, setCount] = useState(1);
  const [notes, setNotes] = useState("-");

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent />;

  return (
    <div className="bg-white relative">
      <div className="absolute top-5 left-5">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <FiChevronLeft color="#fff" />
        </button>
      </div>

      <div className="w-full">
        <img src={dataProduct.product.displayPic} className="w-full h-full" />
      </div>

      <div className="px-4 mt-4">
        <h4 className="f-h4">{dataProduct.product.name}</h4>
        <p className="f-p2-r mt-1">{dataProduct.product.description}</p>
        <h5 className="f-h5 font-bold mt-2">
          Stock :{" "}
          {parseInt(dataProduct.product.stock) > 1000
            ? "Unlimited"
            : dataProduct.product.stock}
        </h5>
      </div>

      <div className="flex px-4 mt-4 items-center justify-between">
        <h2 className="f-h2 text-secondary-border">
          {rupiahFormat(dataProduct.product.price)}
        </h2>

        <div className="flex items-center gap-4">
          <button
            onClick={() => {
              if (count > 1) setCount(count - 1);
            }}
            className="w-10 h-10 bg-gray-200 rounded-lg flex justify-center items-center"
          >
            <FiMinus size={18} />
          </button>
          <div className="text-center w-[36px]">
            <h4 className="f-h4">{count}</h4>
          </div>
          <button
            onClick={() => {
              if (parseInt(dataProduct.product.stock) > count)
                setCount(count + 1);
            }}
            className="w-10 h-10 bg-secondary-border rounded-lg flex justify-center items-center"
          >
            <FiPlus size={18} />
          </button>
        </div>
      </div>

      <div className="px-4 mt-4">
        <textarea
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          className="w-full border border-gray-300 rounded-lg p-2 f-p1-r"
          placeholder="Catatan"
          rows={5}
        />
      </div>

      <div className="px-4 mt-4">
        {parseInt(dataProduct.product.stock) > 0 ? (
          <button
            onClick={() => {
              const data = {
                ...dataProduct.product,
                amount: count,
                notes: notes,
                buy: true,
                idForBuy: new Date().getTime(),
              };
              setCart([...cart, data]);
              navigate(-1);
            }}
            className="flex gap-5 items-center rounded-lg bg-primary-main text-white text-[20px] py-2 px-2 w-full"
          >
            <div className="rounded-lg bg-yellow-400 w-[40px] h-[40px] flex justify-center items-center text-slate-900">
              <FiShoppingCart size={24} />
            </div>
            <h4 className="f-h5">Tambahkan ke keranjang</h4>
          </button>
        ) : (
          <h4 className="text-primary-main f-h4">Produk habis</h4>
        )}
      </div>
      <br />
    </div>
  );
}
