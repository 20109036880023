import React, { version } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import bubbleVertical from "./../../assets/images/bubble-vertical.svg";
import circle from "./../../assets/images/circle.svg";
import imageWoman from "./../../assets/images/woman.png";
import { REACT_APP_VERSION } from "../../version";

export function HomePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("room-id");
  const { customer, level, idTable } = useParams();

  return (
    <div className="h-screen bg-primary-border relative overflow-hidden text-white flex flex-col items-center">
      <img src={bubbleVertical} className="w-full h-full absolute top-12" />
      <div className="absolute top-28 w-[320px]">
        <img src={circle} className="absolute right-0 -top-10" />
        <h4 className="f-h4">
          Selamat Datang, <br />
          <span className="">Pelanggan Dismore!</span>
        </h4>
        <p className="f-p2-r mt-3">
          let's enjoy <span className="font-bold">Dismore</span> apps!
        </p>
        <p className="text-[12px] mt-1">{REACT_APP_VERSION}</p>
      </div>
      <img src={imageWoman} className="absolute bottom-0" />
      <div className="w-full h-[250px] absolute bottom-0 bg-gradient-to-t from-primary-main">
        <div className="w-full h-fit flex flex-col justify-center absolute bottom-10 text-right px-6">
          <p className="f-p2-r text-white mt-3">
            Dapatkan produk terbaik dari <br /> berbagai macam kategori
          </p>
        </div>
      </div>
      <button
        onClick={() => {
          if (!customer || !level || !idTable)
            navigate("/menu" + `?room-id=${roomId}`);
          else navigate(`/${customer}/${level}/${idTable}/menu`);
        }}
        className="bg-secondary-main h-14 w-2/3 rounded-l-full absolute right-0 bottom-36 f-h4 flex items-center gap-5 px-8 text-primary-main"
      >
        Lihat Produk{" "}
        <div className="p-1 rounded-full bg-primary-main text-white">
          <FiChevronRight size={20} />
        </div>
      </button>
    </div>
  );
}
