import image404 from "./../../assets/images/404.png";
import { ButtonComponent } from "../button/button-component";

export function ErrorComponent() {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <img src={image404} />
      <h1 className="text-center font-bold text-[32px] text-gray-900">
        Under Construction
      </h1>

      <div className="w-96 mt-5">
        <ButtonComponent
          title="Refresh App"
          type="button"
          handle={() => {
            window.location.href = "/";
          }}
        />
      </div>
    </div>
  );
}
