import React, { useContext, useEffect, useState } from "react";
import { FiMinus, FiPlus, FiTrash } from "react-icons/fi";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { LanguageContext } from "../../../context/LanguageContext";
import language from "../../../language/data.json";

export function ProductComponent({
  product,
  handleMin,
  handlePlus,
  handleBuy,
  removeProduct,
}) {
  const { lang } = useContext(LanguageContext);

  const [count, setCount] = useState(1);
  const [buy, setBuy] = useState(false);

  useEffect(() => {
    setCount(product.amount);
    setBuy(product.buy);
  }, [product.buy]);

  return (
    <div className="flex px-[75px] py-2 items-center justify-between gap-5">
      <div className="flex items-center lg:gap-10 gap-5">
        <div className="flex items-center">
          <input
            id="default-checkbox"
            type="checkbox"
            onChange={() => {
              handleBuy();
              setBuy(!buy);
            }}
            checked={buy}
            className="w-6 h-6 text-main bg-gray-100 border-gray-300 rounded focus:ring-main dark:focus:ring-main dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>
        <div
          className={`h-fit lg:w-[150px] py-2 px-2 rounded-xl flex flex-col items-center overflow-hidden`}
        >
          <div className="lg:w-[130px] lg:h-[130px] w-20 h-20 overflow-hidden flex justify-center items-center rounded-xl">
            <img src={product.displayPic} className="w-full" />
          </div>
        </div>

        <div>
          <h3 className="font-medium lg:text-[24px] text-[20px] line-clamp-2">
            {product.name} ( X{product.amount} )
          </h3>
          <div className="bg-gray-200 rounded-lg py-2 px-3 mt-2 w-fit">
            <h4 className="text-secondary text-lg font-bold">
              {language.orderList.notes[lang]} :{" "}
              <span className="font-medium">{product.notes}</span>
            </h4>
          </div>
          <h2 className="font-bold lg:text-[32px] text-[24px] text-main mt-1">
            {rupiahFormat(product.originalPrice * product.amount)}
          </h2>
          <div className="hidden mt-3 gap-5">
            <button
              onClick={() => {
                if (count > 1) {
                  setCount(count - 1);
                  handleMin();
                }
              }}
              className="bg-slate-200 text-slate-900 hover:text-white hover:bg-main rounded-lg w-[36px] h-[36px] flex justify-center items-center"
            >
              <FiMinus size={20} />
            </button>
            <div className="text-center w-[30px]">
              <h1 className="font-bold text-[20px]">{count}</h1>
            </div>
            <button
              onClick={() => {
                if (parseInt(product.stock) > count) {
                  setCount(count + 1);
                  handlePlus();
                }
              }}
              className="bg-main text-white hover:text-white hover:bg-main rounded-lg w-[36px] h-[36px] flex justify-center items-center"
            >
              <FiPlus size={20} />
            </button>
          </div>
        </div>
      </div>
      <div className="">
        <button
          onClick={removeProduct}
          className="bg-main text-white rounded-lg px-4 py-4"
        >
          <FiTrash size={24} />
        </button>
      </div>
    </div>
  );
}
