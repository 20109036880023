import { createContext, useState } from "react";

// value lang [id, en, cn, jawa]

type languageSelect = "id" | "en" | "cn" | "jawa";

interface LanguageContextType {
  lang: languageSelect;
  setLang: (lang: languageSelect) => void;
}

export const LanguageContext = createContext<LanguageContextType>({
  lang: "id",
  setLang: (lang: languageSelect) => {},
});

export const LanguageProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [lang, setLang] = useState<languageSelect>("id");

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
};
