import { useQuery } from "@apollo/client";
import { useContext, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ErrorComponent } from "./../../../components/error/error-component";
import { LoadingComponent } from "./../../../components/loading/loading-component";
import { CartContext } from "./../../../context/CartContext";
import { GET_PRODUCTS } from "src/graphql/query/Product";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function SubMenuComponent({ id, name, menuid }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("room-id");
  const { customer, level, idTable } = useParams();

  const {
    loading,
    error,
    data: dataProducts,
  } = useQuery(GET_PRODUCTS, { variables: { submenuId: id } });

  const { cart, setCart } = useContext(CartContext);
  const [productActive, setProductActive] = useState(null);

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent />;

  return (
    <div className="mt-4 px-4">
      <h4 className="f-h4">{name}</h4>
      <div className="grid grid-cols-2 mt-2 gap-4">
        {dataProducts?.products.map((product, index) => {
          if (product.name.includes("[HIDDEN_PRODUCT]-")) return null;
          return (
            <button
              key={index}
              onClick={() => {
                if (!customer || !level || !idTable)
                  navigate(`/menu/${product.id}`);
                else
                  navigate(
                    `/${customer}/${level}/${idTable}/menu/${product.id}?room-id=${roomId}`
                  );
              }}
              className="text-left"
            >
              <img
                src={product.displayPic}
                className="w-full h-28 bg-gray-100"
              />
              <h5 className="font-bold text-[13px] mt-2 line-clamp-2 h-10">
                {product.name}
              </h5>
              <p className="font-bold text-[12px] mt-1 text-orange-400">
                {rupiahFormat(product.originalPrice)}
              </p>
            </button>
          );
        })}
      </div>
    </div>
  );
}
