import { BrowserRouter } from "react-router-dom";
import AworjiwaCoffeeQRRoutes from "./routes";

export default function AworjiwaCoffeeQRRouter() {
  return (
    <BrowserRouter>
      <AworjiwaCoffeeQRRoutes />
    </BrowserRouter>
  );
}
