import axios from "axios";
import { REACT_APP_GRAPHQL_API_URL } from "src/configs/url";
import { handleAxiosError } from "src/service/errors";
import { headers } from "src/service/config";

const CREATE_QRIS_TRANSACTION = `
  mutation CreateQrisTransaction($input: CreateQrisTransactionInput!) {
    createQrisTransaction(input: $input) {
      transaction {
        id
        url
      }
    }
  }
`;

const IS_TRANSACTION_SETTLED = `
  query Query($isTransactionSettledId: ID!) {
    isTransactionSettled(id: $isTransactionSettledId)
  }
`;

export class TransactionGraphServices {
  async createQrisTransaction({ orderCustomerId }: { orderCustomerId: string }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: CREATE_QRIS_TRANSACTION, variables: { input: {orderCustomerId: orderCustomerId} } },
        {
          headers: headers,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  async isTransactionSettled({ id }: { id: string }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: IS_TRANSACTION_SETTLED, variables: { isTransactionSettledId: id,} },
        {
          headers: headers,
        }
      );

      return response.data.data;
    } catch (err) {
      handleAxiosError(err);
      return;
    }
  }
}
