import { AppContextProvider } from "./context/AppContextProvider";
import AppGymaleRouter from "./routers";

export default function AppGymale() {
  return (
    <AppContextProvider>
      <div className="h-screen w-full relative">
        <AppGymaleRouter />
      </div>
    </AppContextProvider>
  );
}
