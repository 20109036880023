import { createContext, useState } from "react";

export const HelpStatusContext = createContext();

export const HelpStatusProvider = (props) => {
  const [helpStatus, setHelpStatus] = useState("idle");

  return (
    <HelpStatusContext.Provider value={{ helpStatus, setHelpStatus }}>
      {props.children}
    </HelpStatusContext.Provider>
  );
};
