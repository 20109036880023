export function ButtonComponent(props) {
  return (
    <button
      onClick={props.handle}
      className={`${
        props.type == "active" ? "bg-main text-white" : `bg-gray-400 text-white`
      } w-full text-[12px] rounded-full px-10 py-2 font-semibold hover:bg-main`}
    >
      {props.title}
    </button>
  );
}
