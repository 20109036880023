import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "../pages/home";
import { MenuPage } from "../pages/menu";
import { OrderListPage } from "../pages/order-list";
import { RatingPage } from "../pages/rating";
import { Page404 } from "../pages/error/404";
import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { OperatorContext } from "../context/OperatorContext";
import { AuthPage } from "../pages/auth";
import { JWTPayload } from "src/utils/JWTPayload";
import { LoadingComponent } from "../components/loading/loading-component";
import { cookies } from "src/service/config";

export default function AppTebuTerusGancitRouter() {
  const { lang, setLang } = useContext(LanguageContext);
  const { operator, setOperator } = useContext(OperatorContext);

  const [load, setLoad] = useState(true);

  useEffect(() => {
    fetch();

    const langTemp = localStorage.getItem("lang");
    if (langTemp) {
      setLang(lang);
    }
  }, []);

  async function fetch() {
    const payload = JWTPayload(cookies?.token ?? "");
    const nextExp = (payload.exp + 43200 * 2 * 7) * 1000;

    if (nextExp / 1000 < new Date().getTime() / 1000) {
      document.cookie = "token=; Max-Age=0";

      setOperator(null);
      window.location.href = "/";
    }

    if (payload) {
      setOperator({
        name: payload.name,
        email: payload.email,
        userRole: payload.user_role,
      });
    }
    setLoad(false);
  }

  if (load == true) {
    return <LoadingComponent />;
  } else if (operator) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/menu" element={<MenuPage />} />

          <Route path="/order-list" element={<OrderListPage />} />

          <Route path="/rating/:idOrderCustomer" element={<RatingPage />} />

          <Route path="/404" element={<Page404 />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/auth" element={<AuthPage />} />
          <Route path="*" element={<AuthPage />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
