import { BsBagCheck } from "react-icons/bs";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function CardProductComponent(props) {
  return (
    <button
      onClick={() => {
        props.handleDetail(new Date().getTime());
      }}
      className="lg:w-full w-11/12 flex flex-col items-center"
    >
      <img
        src={props.item.image}
        className="rounded-t-3xl object-cover lg:w-10/12 lg:h-[300px] h-[100px] relative z-10 lg:top-8 top-6"
      />

      <div className="bg-slate-200 shadow-lg w-full flex-shrink-0 rounded-3xl flex flex-col items-center relative lg:pt-24 pt-16 pb-6 z-0 lg:-top-8 -top-6">
        <div className="relative w-9/12 mx-auto">
          <h2 className="lg:text-[28px] text-[20px] line-clamp-2 leading-tight font-bold lg:h-20 h-12">
            {props.item.name}
          </h2>

          <h2 className="lg:text-[28px] text-[20px] leading-tight line-clamp-1 font-bold text-main mt-1">
            {rupiahFormat(props.item.originalPrice)}
          </h2>
        </div>
      </div>
    </button>
  );
}
