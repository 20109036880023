import { gql } from "@apollo/client";

export const CREATE_QRIS_TRANSACTION = gql`
  mutation CreateQrisTransaction($input: CreateQrisTransactionInput!) {
    createQrisTransaction(input: $input) {
      transaction {
        id
        url
      }
    }
  }
`;
