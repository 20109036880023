import { useNavigate } from "react-router-dom";
import { LanguageContext } from "./../../../context/LanguageContext";
import language from "./../../../language/data.json";
import { useContext } from "react";

export function PopUpCloseSection({ callback }) {
  const navigate = useNavigate();
  const { lang } = useContext(LanguageContext);

  return (
    <div className="bg-slate-600 bg-opacity-60 fixed w-full h-full flex justify-center items-center">
      <div className="flex-col text-center py-8 px-8 bg-white rounded-3xl overflow-hidden shadow-xl flex">
        <h2 className="lg:text-[40px] text-[32px] font-bold mb-2">
          {language.orderList.popUpClose.question[lang]}
        </h2>
        <p className="lg:text-[28px] text-[20px] font-medium mb-4">
          {language.orderList.popUpClose.placeholder[lang]}
        </p>
        <div className="flex justify-center my-4"></div>

        <div className="w-full flex gap-5">
          <button
            onClick={() => {
              window.location.href = "/";
            }}
            className="lg:text-[36px] text-[24px] w-full font-bold bg-secondary text-white hover:bg-main hover:text-white py-4 rounded-2xl shadow-xl"
          >
            {language.orderList.popUpClose.next[lang]}
          </button>
          <button
            onClick={() => {
              callback();
            }}
            className="lg:text-[36px] text-[24px] w-full font-bold bg-main text-white hover:bg-secondary hover:text-white py-4 rounded-2xl shadow-xl"
          >
            {language.orderList.popUpClose.cancel[lang]}
          </button>
        </div>
      </div>
    </div>
  );
}
