import { AppContextProvider } from "./context/AppContextProvider";
import AppAworjiwaCoffeeRouter from "./routers";

export default function AppAworjiwoBromo() {
  return (
    <AppContextProvider>
      <div className="h-screen w-full relative">
        <AppAworjiwaCoffeeRouter />
      </div>
    </AppContextProvider>
  );
}
