import React from "react";

import { CombineComponents } from "./CombineComponents";
import { LoadingProvider } from "./LoadingContext";
import { PageProvider } from "./PageContext";
import { SidebarProvider } from "./SidebarContext";
import { UserProvider } from "./UserContext";
import { CartProvider } from "./CartContext";
import { JSONProvider } from "./JSONContextProvider";

const providers = [
  UserProvider,
  PageProvider,
  LoadingProvider,
  SidebarProvider,
  CartProvider,
  JSONProvider,
];
export const AppContextProvider = CombineComponents(...providers);
