import axios from "axios";
import { REACT_APP_GRAPHQL_API_URL } from "src/configs/url";
import { handleAxiosError } from "src/service/errors";
import { headers } from "src/service/config";

const GET_TENANTS = `
  query Tenants {
    tenants {
      id
      menus {
        name
        isPromotion
        id
        displayPic
      }
      name
    }
  }
`;

export class TenantGraphServices {
  async gets() {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: GET_TENANTS },
        {
          headers: headers,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
