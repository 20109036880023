export function NavbarComponent(props) {
  return (
    <div
      className={`w-full px-5 py-5 items-center text-center relative flex gap-4 justify-between ${
        props.type == "dark" ? "text-primary-main" : "text-white"
      }`}
    >
      {props.leftIcon != null ? (
        <button
          onClick={props.handleLeft}
          className="flex justify-end items-center"
        >
          <props.leftIcon size={24} />
        </button>
      ) : (
        <></>
      )}
      <h3 className="f-h3">{props.title}</h3>
      {props.rightIcon != null ? (
        <button
          onClick={props.handleRight}
          className="flex justify-start items-center"
        >
          <props.rightIcon size={24} />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
