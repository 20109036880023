import { useContext, useEffect, useRef, useState } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import bubbleTwice from "./../../assets/images/bubble-twice.svg";
import iconSuccess from "./../../assets/images/icon/success.svg";
import { ButtonComponent } from "./../../components/button/button-component";
import { CartContext } from "./../../context/CartContext";
import { JSONContext } from "./../../context/JSONContextProvider";
import html2canvas from "html2canvas";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function BillPage() {
  const navigate = useNavigate();

  const { customer, level, idTable } = useParams();
  const { cart } = useContext(CartContext);
  const { JSON, setJSON } = useContext(JSONContext);

  useEffect(() => {
    if (cart.length == 0) {
      window.location.href = `/${customer}/${level}/${idTable}`;
    }
  }, []);

  const handleDownloadImage = async () => {
    const element = document.getElementById("print"),
      canvas = await html2canvas(element),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");

    link.href = data;
    link.download = "BILL_AworjiwaCoffee_" + JSON.idOrderCustomer + ".jpg";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-primary-border relative flex flex-col justify-center items-center py-6 min-h-screen">
      <img src={bubbleTwice} className="w-full h-full absolute" />
      <div
        className="w-11/12 relative bg-white flex flex-col items-center justify-between rounded-2xl p-5"
        id="print"
      >
        <div className="text-center flex flex-col items-center mb-4">
          <img src={iconSuccess} className="w-14" />
          <h3 className="f-h3 mt-4">Sukses</h3>
          <p className="f-p2-r mt-2">Pembayaran berhasil</p>
        </div>

        <BillSquare data={JSON} cart={cart} />
      </div>

      <div className="w-11/12 relative mt-6">
        <div className="w-full">
          <ButtonComponent
            type="light"
            title="Download Struk"
            handle={() => {
              handleDownloadImage();
            }}
          />
        </div>
      </div>
    </div>
  );
}

function BillSquare({ data, cart }) {
  return (
    <div className="w-full">
      <div className="text-center">
        <h1 className="f-h4 mt-0">Aworjiwa Coffee</h1>
        <p className="f-p2-r">
        Jl. Raung No. 64 RT. 005 RW. 002, Ketapang, Kademangan, Probolinggo
          <br />
          Jawa Timur, Indonesia
          <br />
          Telp. +62 852-0498-5741
        </p>
      </div>
      <div className="flex items-center mt-4">
        <div className="grow">
          <h1 class="f-p1-sb">
            Lantai {data.levelNumber} / {data.tableNumber}
          </h1>
        </div>
        <div className="text-right">
          <p className="f-p2-m">Payment by</p>
          <p className="f-p2-r">QRIS</p>
        </div>
      </div>
      <div className="mt-2">
        <p className="f-p1-m">Invoice: {data.idOrderCustomer}</p>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-1">
        <div className="col-span-1">
          <div className="">
            <p className="f-p2-r">Service</p>
            <p className="f-p2-m ">Dine In</p>
          </div>
          <div className="mt-2">
            <p className="f-p2-r">Customer</p>
            <p className="f-p2-m line-clamp-1">{data?.user?.name ?? "Guest"}</p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="">
            <p className="f-p2-r">Order Time</p>
            <p className="f-p2-m ">{data.orderTime}</p>
          </div>
          <div className="mt-2 ">
            <p className="f-p2-r">Collected By</p>
            <p className="f-p2-m ">{data.machine}</p>
          </div>
        </div>
      </div>
      <div className="border border-dashed w-full my-4 border-neutral-600"></div>
      <div className="mt-2">
        <div className="bg-neutral-100">
          <h5 className="f-h5">Order Details</h5>
          {data.orders &&
            data.orders.map((x) => {
              return (
                <div className="list-group mt-1">
                  <div className="flex">
                    <div className="w-3/4">
                      <h5 className="f-h5">{x.name}</h5>
                    </div>
                    <div className="grow text-right">
                      <p className="f-p1-r">x{x.amount}</p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="">
                      <p className="italic f-p1-r">{x.notes}</p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-3/4">
                      <p className="f-p1-r">{x.originalPrice}</p>
                    </div>
                    <div className="grow text-right">
                      <p className="f-p1-sb">{x.totalPrice}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="border border-dashed w-full my-4 border-neutral-600"></div>
      <div className="mt-2">
        <div className="flex justify-between items-center">
          <p className="f-p2-r">Sub Total</p>
          <p className="f-p2-r">{data.subTotal}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="f-p2-r">Discount</p>
          <p className="f-p2-r">{data.discount}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="f-p2-r">Tax 10%</p>
          <p className="f-p2-r">{data.tax}</p>
        </div>
      </div>
      <div className="border border-dashed w-full my-4 border-neutral-600"></div>
      <div className="mt-2 flex items-center justify-between">
        <h5 className="f-h5">Total</h5>
        <h5 className="f-h5">{data.total}</h5>
      </div>
    </div>
  );
}
