import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { MembersServices } from "src/service/MembersServices";

export function MemberListPage() {
  const navigate = useNavigate();
  const membersServices = new MembersServices();
  const limit = 20;

  const [members, setMembers] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [tempMembers, setTempMembers] = useState({
    data: [],
    meta: {
      total: 0,
    },
  });

  const [offset, setOffset] = useState(0);

  const [load, setLoad] = useState(true);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch(pOffset) {
    setLoad(true);
    const res = await membersServices.gets({
      limit: limit,
      offset: pOffset ?? offset,
    });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const temp = res.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          email: item.email,
        };
      });
      setMembers({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });

      setTempMembers({
        data: temp,
        meta: {
          total: res.meta.total,
        },
      });
    }
    setLoad(false);
  }

  const handleSearch = (text) => {
    const filtered = tempMembers.data.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    );

    setMembers({
      data: filtered,
      meta: {
        total: filtered.length,
      },
    });
  };

  return (
    <div className="col-span-12">
      <div className="flex items-center justify-between">
        <h1 className="text-[32px] font-bold">List Member</h1>
        <button
          onClick={() => {
            navigate("/member/add");
          }}
          className="py-5 px-8 rounded-full bg-main text-[24px] font-bold"
        >
          Add Member
        </button>
      </div>

      <div>
        {load ? (
          <div>Loading...</div>
        ) : (
          <div>
            <div className="grid grid-cols-12 gap-4 mt-5">
              {members?.data.map((item, index) => {
                return (
                  <CardMember
                    key={index}
                    item={item}
                    callback={() => {
                      navigate("/member/detail/" + item.id);
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-end mt-10">
        <PaginationComponent
          active={offset}
          data={Array.from(
            { length: members?.meta?.total / limit + 1 },
            (_, index) => index + 1
          )}
          callback={(index) => {
            fetch(index * limit);
            setOffset(index);
          }}
        />
      </div>
    </div>
  );
}

function CardMember({ item, callback }) {
  return (
    <div className=" bg-white shadow-lg py-8 px-6 rounded-lg h-fit col-span-6 grid grid-cols-12">
      <div className="flex flex-col gap-2 col-span-11">
        <div className="grid grid-cols-4">
          <div className="col-span-1">
            <h5 className="text-[20px] font-bold">ID</h5>
          </div>
          <div className="col-span-3">
            <h5 className="text-[20px]">{item.id}</h5>
          </div>
        </div>
        <div className="grid grid-cols-4">
          <div className="col-span-1">
            <h5 className="text-[20px] font-bold">Name</h5>
          </div>
          <div className="col-span-3">
            <h5 className="text-[20px]">{item.name}</h5>
          </div>
        </div>
        <div className="grid grid-cols-4">
          <div className="col-span-1">
            <h5 className="text-[20px] font-bold">Email</h5>
          </div>
          <div className="col-span-3">
            <h5 className="text-[20px]">{item.email}</h5>
          </div>
        </div>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <button onClick={callback}>
          <FiChevronRight size={28} />
        </button>
      </div>
    </div>
  );
}

function PaginationComponent({ active, data, callback }) {
  return (
    <div>
      {data.map((item, index) => {
        return (
          <button
            key={index}
            onClick={() => {
              callback(index);
            }}
            className={`p-5 rounded-lg ${index == active ? "bg-main" : ""}`}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
}
