import { createContext, useState } from "react";

export const OperatorContext = createContext();

export const OperatorProvider = (props) => {
  const [operator, setOperator] = useState();

  return (
    <OperatorContext.Provider value={{ operator, setOperator }}>
      {props.children}
    </OperatorContext.Provider>
  );
};
