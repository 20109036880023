import { AppContextProvider } from "./context/AppContextProvider";
import AppCafetariaUnairBRouter from "./routers";

export default function AppCafetariaUnairB() {
  return (
    <AppContextProvider>
      <div className="h-screen w-full relative">
        <AppCafetariaUnairBRouter />
      </div>
    </AppContextProvider>
  );
}
