import { useNavigate } from "react-router-dom";
import imageEmpty from "./../../../assets/images/image1.svg";
import { ButtonComponent } from "../../../components/button/button-component";
import { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import language from "../../../language/data.json";

export function EmptyComponent() {
  const { lang } = useContext(LanguageContext);

  const navigate = useNavigate();
  return (
    <div className="grow w-full flex flex-col justify-center items-center">
      <img src={imageEmpty} className="w-[200px]" />
      <h1 className="mt-5 text-center font-bold text-[32px] text-gray-900">
        {language.orderList.empty[lang]}
      </h1>
      <div className="w-96 mt-5">
        <ButtonComponent
          title="Menu"
          type="button"
          handle={() => {
            navigate(-1);
          }}
        />
      </div>
    </div>
  );
}
