import { useContext } from "react";
import imageThumb from "./../../../assets/icon/thumb.svg";
import { LanguageContext } from "../../../context/LanguageContext";
import language from "../../../language/data.json";

export function PaymentSuccessSection() {
  const { lang } = useContext(LanguageContext);

  return (
    <div className="bg-slate-600 bg-opacity-90 fixed z-40 w-full h-full flex justify-center items-center">
      <div className="relative h-full w-full flex flex-col items-center justify-center gap-8">
        <div className="w-1/3">
          <img src={imageThumb} className="w-full" />
        </div>
        <div className="my-2">
          <h2 className="text-[48px] font-bold">
            {language.orderList.paymentSuccess.title[lang]}
          </h2>
        </div>
      </div>
    </div>
  );
}
