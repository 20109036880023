import { AppContextProvider } from "./context/AppContextProvider";
import App45CoffeeRouter from "./routers";

// test change

export default function App45Coffee() {
  return (
    <AppContextProvider>
      <div className="h-screen w-full relative">
        <App45CoffeeRouter />
      </div>
    </AppContextProvider>
  );
}
