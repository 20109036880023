export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const REACT_APP_API_URL =
  process.env.REACT_APP_API_URL || REACT_APP_BASE_URL + "/api/v1";

export const REACT_APP_GRAPHQL_API_URL =
  process.env.REACT_APP_GRAPHQL_API_URL || REACT_APP_BASE_URL + "/graphql/v1";
export const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY;

export const REACT_APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

