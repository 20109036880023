import {Navigate, Route, Routes, useSearchParams} from "react-router-dom";
import {HomePage} from "../pages/home";
import {MenuPage} from "../pages/menu";
import {MenuDetailPage} from "../pages/menu-detail";
import {CartPage} from "../pages/cart";
import {PaymentPage} from "../pages/payment";
import {BillPage} from "../pages/bill";
import {SecretService} from "../../../service/SecretService";
import {useEffect, useState} from "react";
import {Page404} from "../pages/error/404";
import {TimeServices} from "../../../service/TimeServices";

export default function AworjiwaCoffeeQRRoutes() {
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState(null);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [ time, setTime] = useState(0);

    useEffect(() => {
        if (!token) {
            SecretService.safeURLJSONDecode(searchParams.get('token')).then((decodedJSON) => {
                setToken(decodedJSON);
            }).catch(() => {
                setIsTokenValid(false);
            });
            return;
        }

        if (!(token && token.created_at)) return;

        let diffMinutes = 0;
        try {
            const momentNow = (new TimeServices()).setLocale().getMomentDate();
            const momentToken = TimeServices.parse(token.created_at).setLocale().getMomentDate();
            diffMinutes = momentNow.diff(momentToken, 'minutes');

            if (diffMinutes < 120) {
                setIsTokenValid(true);
            } else {
                setIsTokenValid(false);
            }
        } catch {
            setIsTokenValid(false);
            return;
        }

        const MAX_TIME = 120 * 60;
        setTime(diffMinutes * 60)
        const timerID = setInterval(() => {
            setTime((time) => {
                if (time >= MAX_TIME) {
                    setIsTokenValid(false);
                    clearInterval(timerID);
                    return MAX_TIME;
                }

                return time + 1;
            })
        }, 1000);

        return () => {
            if (!timerID) return;

            clearInterval(timerID);
        }
    }, [token, searchParams, isTokenValid])

    if (!isTokenValid) return <Page404 />;

    return (
        <Routes>
            <Route path="/:customer/:level/:idTable" element={<HomePage/>}/>
            <Route path="/:customer/:level/:idTable/menu" element={<MenuPage/>}/>
            <Route
                path="/:customer/:level/:idTable/menu/:id"
                element={<MenuDetailPage/>}
            />
            <Route path="/:customer/:level/:idTable/cart" element={<CartPage/>}/>
            <Route
                path="/:customer/:level/:idTable/payment/:idMember/"
                element={<PaymentPage/>}
            />
            <Route path="/:customer/:level/:idTable/bill" element={<BillPage/>}/>
            <Route path="*" element={<Navigate to="/guest/1/1"/>}/>
        </Routes>
    );
}