import React from "react";
import { CartProvider } from "./CartContext";

import { CombineComponents } from "./CombineComponents";
import { LoadingProvider } from "./LoadingContext";
import { SidebarProvider } from "./SidebarContext";
import { UserProvider } from "./UserContext";
import { LanguageProvider } from "./LanguageContext";
import { OperatorProvider } from "./OperatorContext";
import { HelpStatusProvider } from "./HelpStatusContext";

const providers = [
  UserProvider,
  LoadingProvider,
  CartProvider,
  SidebarProvider,
  LanguageProvider,
  OperatorProvider,
  HelpStatusProvider,
];
export const AppContextProvider = CombineComponents(...providers);
