import { FiHome } from "react-icons/fi";
import banner1 from "./../../assets/images/banner/1.jpg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GET_TENANTS } from "src/graphql/query/Tenant";
import { GET_SUBMENUS } from "src/graphql/query/Submenu";
import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { LoadingComponent } from "./../../components/loading/loading-component";
import { ErrorComponent } from "./../../components/error/error-component";
import { SubMenuComponent } from "./component/SubMenuComponent";
import { CartContext } from "./../../context/CartContext";
import iconCart from "./../../assets/images/icon/cart.svg";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function MenuPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("room-id");
  const { customer, level, idTable } = useParams();

  const { cart, setCart } = useContext(CartContext);

  const {
    loading: loadingTenants,
    error: errorTenants,
    data: dataTenants,
  } = useQuery(GET_TENANTS);

  const [menuActive, setMenuActive] = useState(
    dataTenants?.tenants[0]?.menus[0]?.id
  );

  const {
    loading: loadingSubmenus,
    error: errorSubmenus,
    data: dataSubmenus,
    refetch: refetchSubmenus,
  } = useQuery(GET_SUBMENUS, { variables: { menuId: menuActive } });

  const [subMenuActive, setSubMenuActive] = useState(
    dataSubmenus?.submenus[0]?.id
  );

  useEffect(() => {
    setMenuActive(dataTenants?.tenants[0]?.menus[0]?.id);
  }, [dataTenants]);

  if (loadingTenants) return <LoadingComponent />;
  if (errorTenants) return <ErrorComponent />;

  return (
    <div className="h-screen overflow-hidden flex flex-col items-center">
      <div className="bg-white w-full h-24 relative grid grid-cols-12 shadow-s1">
        <button
          onClick={() => {
            if (!customer || !level || !idTable)
              navigate(`/?room-id=${roomId}`);
            else navigate(`/${customer}/${level}/${idTable}`);
          }}
          className="bg-primary-main col-span-2 h-full flex justify-center items-center rounded-r-xl"
        >
          <FiHome color="#fff" size={32} />
        </button>
        <div className="col-span-10 flex flex-col justify-center pl-4">
          <h4 className="f-h4 text-primary-main">Menu</h4>
          <p className="f-p2-r text-gray-600">
            Temukan dan Dapatkan Makanan Lezat
          </p>
        </div>
      </div>

      <div
        className="relative grid grid-cols-12"
        style={{ height: "calc(100vh - 96px)" }}
      >
        <div className="col-span-2 overflow-y-scroll">
          {dataTenants.tenants.toReversed().map((tenant, index) => {
            return tenant.menus.toReversed().map((menu, i) => {
              return (
                <button
                  key={i}
                  onClick={() => {
                    setMenuActive(menu.id);
                  }}
                  className={`h-fit py-3 w-full flex flex-col items-center justify-center gap-2 mb-2 rounded-r-xl ${
                    menuActive == menu.id ? "bg-secondary-main" : "bg-white"
                  }`}
                >
                  <img src={menu.displayPic} className="w-8" />
                  <p className="f-p2-sb text-primary-main text-center">
                    {menu.name}
                  </p>
                </button>
              );
            });
          })}
        </div>
        <div className="overflow-y-scroll overflow-x-hidden col-span-10">
          {dataSubmenus &&
            dataSubmenus.submenus.map((submenu, index) => {
              return (
                <SubMenuComponent
                  key={index}
                  id={submenu.id}
                  name={submenu.name}
                  menuId={menuActive}
                />
              );
            })}
          <br />
        </div>
      </div>

      {cart.length == 0 ? null : (
        <button
          onClick={() => {
            if (!customer || !level || !idTable)
              navigate("/cart" + `?room-id=${roomId}`);
            else navigate(`/${customer}/${level}/${idTable}/cart`);
          }}
          className="fixed bottom-8 w-3/4 flex items-center"
        >
          <div className="bg-primary-main rounded-xl text-left text-white py-2 px-5 w-full">
            <h5 className="f-h5">Pesan Sekarang</h5>
            <p className="f-p2-r">
              Total{" "}
              {rupiahFormat(
                cart.reduce(
                  (temp, x) => temp + x.originalPrice * x.amount,
                  0,
                  0
                )
              )}{" "}
              | Item: {cart.reduce((temp, x) => temp + x.amount, 0)}
            </p>
          </div>
          <div className="absolute -right-4 bg-secondary-main rounded-full p-5 shadow-s3">
            <img src={iconCart} className="w-8" />
          </div>
        </button>
      )}
    </div>
  );
}
