import React, { useContext, useEffect, useState } from "react";
import { FiMinus, FiPlus, FiTrash } from "react-icons/fi";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function ProductComponent({
  product,
  handleMin,
  handlePlus,
  handleBuy,
  removeProduct,
}) {
  const [count, setCount] = useState(1);
  const [buy, setBuy] = useState(false);

  useEffect(() => {
    setCount(product.amount);
    setBuy(product.buy);
  }, [product.buy]);

  return (
    <div className="flex px-2 py-2 items-center justify-between gap-4">
      <div className="flex items-center gap-4">
        <div className="flex items-center">
          <input
            id="default-checkbox"
            type="checkbox"
            onChange={() => {
              handleBuy();
              setBuy(!buy);
            }}
            checked={buy}
            className="w-6 h-6 text-main bg-gray-100 border-gray-300 rounded focus:ring-main dark:focus:ring-main dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>

        <div className="w-[100px] h-[100px] overflow-hidden flex justify-center items-center">
          <img src={product.displayPic} className="w-full" />
        </div>

        <div>
          <h5 className="font-medium f-h5">{product.name}</h5>
          <h5 className="font-bold f-h5 text-secondary-border text-main mt-1">
            {rupiahFormat(product.originalPrice)}
          </h5>
          <p className="text-main f-p1-r font-bold">
            Notes : <span className="font-medium">{product.notes}</span>
          </p>
          <div className="flex mt-3 gap-5">
            <h5 className="font-bold f-h5">{count} x</h5>
          </div>
        </div>
      </div>
      <div className="">
        <button
          onClick={removeProduct}
          className="bg-main text-white rounded-lg px-4 py-4"
        >
          <FiTrash size={24} />
        </button>
      </div>
    </div>
  );
}
