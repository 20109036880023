export function ButtonComponent(props) {
  return (
    <button
      onClick={props.handle}
      className={`${
        props.type == "light"
          ? "bg-white text-main"
          : "bg-primary-main text-white"
      } w-full f-h4 rounded-full py-4 font-bold hover:bg-primary-main hover:text-white`}
    >
      {props.title}
    </button>
  );
}

export function ButtonComponentLite(props) {
  return (
    <button
      onClick={props.handle}
      className={`${
        props.type == "light"
          ? "bg-white text-main"
          : "bg-primary-main text-white"
      } w-full text-[20px] rounded-full px-10 py-2 font-semibold hover:bg-primary-main`}
    >
      {props.title}
    </button>
  );
}
