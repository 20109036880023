import { useContext, useState } from "react";
import { FiEye, FiMinus, FiPlus, FiShoppingCart, FiX } from "react-icons/fi";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { LanguageContext } from "./../../../context/LanguageContext";
import language from "./../../../language/data.json";
import { ProductServices } from "src/service/ProductServices";
import toast from "react-hot-toast";
import { OperatorContext } from "../../../context/OperatorContext";

export function PopUpProductComponent({ product, callback, handleClose }) {
  const productServices = new ProductServices();

  const { lang } = useContext(LanguageContext);
  const { operator } = useContext(OperatorContext);
  const [count, setCount] = useState(1);
  const [notes, setNotes] = useState("-");

  async function handleShowProduct() {
    const id = product.id;
    const name = product.name;
    const keyHide = "[HIDDEN_PRODUCT]-";

    let newName = "";
    if (name.includes(keyHide)) {
      newName = name.replace(keyHide, "").trim();
    } else {
      newName = `${keyHide}${name}`;
    }

    const res = await productServices.update({ name: newName, id: id });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success hide / show product");
      window.location.reload();
    }
  }

  return (
    <div className="w-full h-full bg-slate-600 bg-opacity-60 fixed z-20 flex justify-center items-center left-0 top-0">
      <div className="lg:w-7/12 w-3/4 lg:min-h-[600px] bg-white rounded-3xl px-[50px] py-[35px] text-center flex flex-col items-center relative">
        <button
          className="absolute right-5 top-5"
          onClick={() => {
            handleClose();
          }}
        >
          <FiX size={24} />
        </button>
        <h3 className="font-bold text-[24px] w-10/12 line-clamp-3">
          {product.name}
        </h3>
        <p className="line-clamp-2 text-[20px]">{product.description}</p>

        <h4 className="text-[20px] font-bold">
          {language.menu.stock[lang]} :{" "}
          {parseInt(product.stock) > 1000 ? "Unlimited" : product.stock}
        </h4>

        <div className="mt-6 w-full overflow-hidden flex justify-center items-center">
          <img src={product.displayPic} className="lg:w-full w-32" />
        </div>

        <div className="mt-8">
          <h3 className="font-bold lg:text-[40px] text-[32px] text-main">
            {rupiahFormat(product.originalPrice)}
          </h3>
        </div>

        <div className="flex mt-8 gap-5">
          <button
            onClick={() => {
              if (count > 1) setCount(count - 1);
            }}
            className="bg-slate-200 text-slate-900 hover:text-white hover:bg-main rounded-xl w-[60px] h-[60px] flex justify-center items-center"
          >
            <FiMinus size={24} />
          </button>
          <div className="text-center w-[100px]">
            <h1 className="font-extrabold text-[35px]">{count}</h1>
          </div>
          <button
            onClick={() => {
              if (parseInt(product.stock) > count) setCount(count + 1);
            }}
            className="bg-slate-200 text-slate-900 hover:text-white hover:bg-main rounded-xl w-[60px] h-[60px] flex justify-center items-center"
          >
            <FiPlus size={24} />
          </button>
        </div>

        <div className="mt-8 w-full">
          <h3 className="font-bold text-[20px] text-left">
            {language.menu.notes[lang]}
          </h3>
          <textarea
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            className="w-full rounded-lg bg-white outline-none border border-slate-200 p-3 mt-3 text-xl"
            rows={3}
            placeholder="spicy, medium, or original"
          ></textarea>
        </div>

        <div className="flex justify-center mt-10 gap-4">
          {operator.userRole == "manager" ? (
            <button
              className="
            flex gap-5 items-center rounded-lg bg-slate-900 text-white text-[20px] py-2 px-2 pr-4"
              onClick={() => {
                handleShowProduct();
              }}
            >
              <div className="rounded-lg bg-gray-400 w-[40px] h-[40px] flex justify-center items-center text-slate-900">
                <FiEye size={24} />
              </div>{" "}
              {product.name.includes("[HIDDEN_PRODUCT]-")
                ? "Show Product"
                : "Hide Product"}
            </button>
          ) : null}
          {parseInt(product.stock) > 0 ? (
            <button
              onClick={() => {
                callback({ count: count, notes: notes });
              }}
              className="flex gap-5 items-center rounded-lg bg-slate-900 text-white text-[20px] py-2 px-2 min-w-[200px]"
            >
              <div className="rounded-lg bg-main w-[40px] h-[40px] flex justify-center items-center text-slate-900">
                <FiShoppingCart size={24} />
              </div>
              {language.menu.addToCart[lang]}
            </button>
          ) : (
            <h4 className="text-main f-h4">{language.menu.soldOut[lang]}</h4>
          )}
        </div>
      </div>
    </div>
  );
}
