import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ORDER } from "src/graphql/mutation/CreateOrder";
import { CREATE_QRIS_TRANSACTION } from "src/graphql/mutation/CreateQrisTransaction";
import { TransactionServices } from "src/service/TransactionServices";
import { ErrorServices } from "src/service/ErrorServices";
import { UserContext } from "../../../context/UserContext";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { CREATE_STRIPE_TRANSACTION } from "src/graphql/mutation/CreateStripeTransaction";
import { CREATE_ORDER_CASH } from "src/graphql/mutation/CreateOrderCash";
import { MoneyAmountComponent } from "../component/MoneyAmountComponent";
import { LanguageContext } from "./../../../context/LanguageContext";
import language from "./../../../language/data.json";

export function PaymentSection({
  tableNumber,
  action,
  typeService,
  cart,
  handleCancel,
  handlePrint,
}) {
  const transactionServices = new TransactionServices();
  const errorServices = new ErrorServices();

  const { lang } = useContext(LanguageContext);
  const { user } = useContext(UserContext);

  const [qrCodeQris, setQrCodeQris] = useState(null);
  const [qrCodeStripe, setQrCodeStripe] = useState(null);
  const [dataOrder, setDataOrder] = useState(null);
  const [time, setTime] = useState(200);

  const [buttonclick, setButtonClick] = useState("");

  const [
    createOrder,
    {
      loading: loadingCreateOrder,
      error: errorCreateOrder,
      data: dataCreateOrder,
    },
  ] = useMutation(CREATE_ORDER);

  const [
    createQrisTransaction,
    {
      loading: loadingCreateQrisTransaction,
      error: errorCreateQrisTransaction,
      data: dataCreateQrisTransaction,
    },
  ] = useMutation(CREATE_QRIS_TRANSACTION);

  const [
    createStripeTransaction,
    {
      loading: loadingCreateStripeTransaction,
      error: errorCreateStripeTransaction,
      data: dataCreateStripeTransaction,
    },
  ] = useMutation(CREATE_STRIPE_TRANSACTION);

  const [
    createOrderCash,
    {
      loading: loadingCreateOrderCash,
      error: errorCreateOrderCash,
      data: dataCreateOrderCash,
    },
  ] = useMutation(CREATE_ORDER_CASH);

  async function handlePaymentCash({ moneyAmount }) {
    const tempOrder = cart.map((item) => {
      return { productId: item.id, quantity: item.amount, notes: item.notes };
    });

    const res = await createOrderCash({
      variables: {
        input: {
          orderInput: tempOrder,
          orderType:
            typeService == "dine-in"
              ? "DINE_IN"
              : typeService == "take-away"
              ? "TAKE_OUT"
              : "DINE_IN",
          tableNumber: tableNumber,
          customerPhone: user?.phone || null,
        },
      },
    });

    if (loadingCreateOrderCash == false && errorCreateOrderCash == undefined) {
      await handlePrint({
        orderId: res.data.createCashOrderCustomer.orderCustomer.id,
        typePayment: "CASH",
        moneyAmount: moneyAmount,
      });

      action(res.data.createCashOrderCustomer.orderCustomer);
    } else {
      return false;
    }
  }

  async function handlePayment({ paymentType }) {
    const tempOrder = cart.map((item) => {
      return { productId: item.id, quantity: item.amount, notes: item.notes };
    });

    const resCreateOrder = await createOrder({
      variables: {
        input: {
          orderInput: tempOrder,
          orderType:
            typeService == "dine-in"
              ? "DINE_IN"
              : typeService == "take-away"
              ? "TAKE_OUT"
              : "DINE_IN",
          tableNumber: tableNumber,
          customerPhone: user?.phone || null,
        },
      },
    });

    if (loadingCreateOrder == false && errorCreateOrder == undefined) {
      setDataOrder(resCreateOrder.data.createOrderCustomer.orderCustomer);

      if (paymentType == "stripe") {
        const resPaymentStripe = await handlePaymentStripe(
          resCreateOrder.data.createOrderCustomer.orderCustomer
        );
        return resPaymentStripe;
      } else {
        const resPaymentQris = await handlePaymentQris(
          resCreateOrder.data.createOrderCustomer.orderCustomer
        );
        return resPaymentQris;
      }
    } else {
      return false;
    }
  }

  async function handlePaymentStripe(resOrder) {
    try {
      const res = await createStripeTransaction({
        variables: {
          input: {
            orderCustomerId: resOrder.id,
          },
        },
      });

      if (
        loadingCreateStripeTransaction == false &&
        errorCreateStripeTransaction == undefined
      ) {
        const image64 = res.data.createStripeTransaction.qrCode;
        const imageSVG = image64.split("data:image/svg;base64,")[1];
        const image = atob(imageSVG);
        let img = image.replace(`width="451"`, `width="100%"`);
        img = img.replace(`height="451"`, `height="100%"`);
        setQrCodeStripe(img);

        // handlePrint({
        //   orderId: res.data.createStripeTransaction.transaction.id,
        // });
        const resStripe = await handleWaiting({
          id: res.data.createStripeTransaction.transaction.id,
          url: res.data.createStripeTransaction.transaction.url,
          resOrder: resOrder,
        });
        return resStripe;
      } else {
        return false;
      }
    } catch (error) {
      errorServices.Create({
        message: error.message,
      });

      setTimeout(() => {
        handlePayment({ paymentType: "stripe" });
      }, 3000);
    }
  }

  async function handlePaymentQris(resOrder) {
    try {
      const res = await createQrisTransaction({
        variables: {
          input: {
            orderCustomerId: resOrder.id,
          },
        },
      });

      if (
        loadingCreateQrisTransaction == false &&
        errorCreateQrisTransaction == undefined
      ) {
        setQrCodeQris(res.data.createQrisTransaction.transaction.url);

        handlePrint({
          orderId: res.data.createQrisTransaction.transaction.id,
          typePayment: "QRIS",
          moneyAmount: resOrder.totalAmount,
        });

        const resMidtrans = await handleWaiting({
          id: res.data.createQrisTransaction.transaction.id,
          url: res.data.createQrisTransaction.transaction.url,
          resOrder: resOrder,
        });
        return resMidtrans;
      } else {
        return false;
      }
    } catch (error) {
      errorServices.Create({
        message: error.message,
      });

      setTimeout(() => {
        handlePayment({ paymentType: "qris" });
      }, 3000);
    }
  }

  async function handleWaiting({ id, url, resOrder }) {
    const res = await transactionServices.isTransactionSettled({ id });

    if (res?.data?.isTransactionSettled == true) {
      action(resOrder);
    } else {
      setTimeout(() => {
        handleWaiting({ id: id, url: url, resOrder: resOrder });
      }, 2000);
    }
  }

  return (
    <div className="bg-slate-600 bg-opacity-60 fixed w-full h-full flex justify-center items-center z-40">
      <div className="bg-transparent scale-[1.75]">
        {buttonclick == "cash" ? (
          <MoneyAmountComponent
            callback={async (moneyAmount) => {
              const subTotal = cart.reduce(
                (temp, x) =>
                  x.buy == true ? temp + x.originalPrice * x.amount : temp,
                0,
                0
              );

              const tax = subTotal * 0.1;
              const total = subTotal + tax;

              if (moneyAmount >= total) {
                await handlePaymentCash({ moneyAmount });
              } else {
                alert(
                  "Your money is not enough, at least " + rupiahFormat(total)
                );
              }
            }}
            handleCancel={() => {
              setButtonClick(false);
            }}
          />
        ) : null}
        <div
          className={`h-full w-[450px] flex flex-col items-center justify-center gap-8 scale-75 ${
            buttonclick != "" ? "hidden" : "block"
          }`}
        >
          <button
            onClick={async () => {
              setButtonClick("qris");
              const res = await handlePayment({ paymentType: "qris" });

              let tempTime = time;
              const interval = setInterval(() => {
                tempTime -= 1;
                setTime(tempTime);
              }, 1000);

              setTimeout(() => {
                window.location.reload();
              }, 200000);
            }}
            className={`lg:text-[40px] text-[24px] font-bold hover:bg-secondary hover:text-white w-full py-6 rounded-2xl shadow-xl bg-main text-white `}
          >
            {language.orderList.payment.qris.label[lang]}
          </button>
          <button
            onClick={async () => {
              setButtonClick("cash");
            }}
            className={`lg:text-[40px] text-[24px] font-bold hover:bg-secondary hover:text-white w-full py-6 rounded-2xl shadow-xl bg-main text-white `}
          >
            {language.orderList.payment.cash.label[lang]}
          </button>
        </div>
        {qrCodeQris ? (
          <div className="bg-white shadow-xl flex flex-col justify-center items-center rounded-lg px-1 py-5 w-[250px] mx-auto">
            <h2 className="lg:f-h2 f-h3 text-center text-main">
              {language.orderList.payment.qris.label[lang]}
            </h2>
            <p className="text-center mt-2 text-[12px]">
              {language.orderList.payment.qris.title[lang]}{" "}
            </p>
            <p className="text-orange-700 font-semibold mt-2 lg:text-[16px] text-[14px]">
              {language.orderList.payment.qris.expired[lang]}{" "}
              {Math.floor(time / 60) < 10
                ? "0" + Math.floor(time / 60)
                : Math.floor(time / 60)}
              {" : "}
              {time % 60 < 10 ? "0" + (time % 60) : time % 60}
            </p>
            <img src={qrCodeQris} className="w-full" />
            <h4 className="f-h4 text-center text-main">
              {rupiahFormat(dataOrder.totalAmount)}
            </h4>

            <div className="px-2 w-full mt-4">
              <button
                onClick={() => {
                  setQrCodeQris(null);
                  setTime(200);
                  handleCancel();
                }}
                className="lg:text-[18px] text-[14px] w-full font-bold bg-gray-400 text-white hover:bg-secondary hover:text-white py-2 rounded-xl shadow-xl"
              >
                {language.orderList.payment.qris.cancel[lang]}
              </button>
            </div>
          </div>
        ) : qrCodeStripe ? (
          <div className="bg-white shadow-xl flex flex-col justify-center items-center rounded-lg px-1 py-5 w-[300px] h-[500px] mx-auto">
            <h2 className="f-h2 text-center text-main">STRIPE</h2>
            <p className="text-center mt-2 text-[12px]">
              Pindai STRIPE dan tunggu beberapa saat ...{" "}
            </p>
            <p className="text-orange-700 font-semibold mt-2">
              expired in{" "}
              {Math.floor(time / 60) < 10
                ? "0" + Math.floor(time / 60)
                : Math.floor(time / 60)}
              {" : "}
              {time % 60 < 10 ? "0" + (time % 60) : time % 60}
            </p>
            <div className="h-[250px]"></div>
            <div
              dangerouslySetInnerHTML={{
                __html: qrCodeStripe,
              }}
              className="w-[450px] h-[450px] scale-50 absolute top-8
            "
            />
            <h4 className="f-h4 text-center text-main">
              ${dataOrder.totalAmount / 100}
            </h4>

            <div className="px-2 w-full mt-4">
              <button
                onClick={() => {
                  setQrCodeStripe(null);
                  setTime(200);
                  handleCancel();
                }}
                className="text-[18px] w-full font-bold bg-gray-400 text-white hover:bg-secondary hover:text-white py-2 rounded-xl shadow-xl"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
