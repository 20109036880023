import axios from "axios";
import { REACT_APP_GRAPHQL_API_URL } from "src/configs/url";
import { handleAxiosError } from "src/service/errors";
import { headers } from "src/service/config";

const CREATE_ORDER = `
  mutation CreateOrderCustomer($input: CreateOrderCustomerInput!) {
    createOrderCustomer(input: $input) {
      orderCustomer {
        id
        orderType
        totalAmount
        totalQty
      }
    }
  }
`;

const CREATE_ORDER_CASH = `
  mutation Mutation($input: CreateCashOrderCustomerInput!) {
    createCashOrderCustomer(input: $input) {
      orderCustomer {
        id
        orderType
        totalAmount
        totalQty
      }
    }
  }
`;


export class OrderGraphServices {
  async createOrder({ orderCustomer }: { orderCustomer: any }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: CREATE_ORDER, variables: { input: orderCustomer } },
        {
          headers: headers,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  async createOrderCash({ orderCustomer }: { orderCustomer: any }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: CREATE_ORDER_CASH, variables: { input: orderCustomer } },
        {
          headers: headers,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
