import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import language from "./../../language/data.json";

export function NotionComponent() {
  const { lang } = useContext(LanguageContext);

  return (
    <div className="bg-red-600 w-full h-[40px] block items-center justify-center">
      <p className="text-[24px] text-center text-white">
        {language.marque.text1[lang]}
      </p>
    </div>
  );
}
