import { useState } from "react";
import { ButtonComponent } from "../../../components/button/button-component";
import { useParams } from "react-router-dom";

export function LevelSection({ handleCancel, callback }) {
  const { customer, level, idTable } = useParams();

  const [idLevel, setIdLevel] = useState(level);

  return (
    <div className="bg-slate-600 bg-opacity-60 fixed w-full h-full flex justify-center items-center">
      <div className="rounded-3xl shadow-x bg-white">
        <div className="flex-col text-center py-8 px-8 flex">
          <h3 className="f-h3 mb-2"> Anda ingin makan di lantai berapa?</h3>
          <div className="flex justify-center my-4">
            <input
              className="w-full rounded-lg bg-white outline-none border border-slate-200 p-3 f-h4 text-center"
              placeholder="1"
              type="number"
              min={1}
              max={5}
              onChange={(e) => {
                setIdLevel(e.target.value);
              }}
              value={idLevel}
            />
          </div>

          <div className="w-full flex gap-5 mt-2">
            <button
              onClick={() => {
                handleCancel();
              }}
              className="f-h5 w-full font-bold bg-slate-600 text-white hover:bg-primary-main py-3 rounded-2xl shadow-xl"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (idLevel >= 1 && idLevel <= 5) callback(idLevel);
              }}
              className="f-h5 w-full font-bold bg-primary-main text-white hover:bg-primary-surface py-3 rounded-2xl shadow-xl"
            >
              Lanjutkan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
