import { useContext } from "react";
import iconX from "./../../../assets/icon/x.svg";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { LanguageContext } from "../../../context/LanguageContext";
import language from "../../../language/data.json";
import { Cart, Order, ProductCart } from "src/types/init";
import { useParams } from "react-router-dom";

export function BillSection({
  order,
  handleCancel,
  cart,
  callback,
}: {
  order: Order;
  handleCancel: () => void;
  cart: Cart;
  callback: () => void;
}) {
  const { typeService } = useParams();
  const { lang } = useContext(LanguageContext);

  return (
    <div className="bg-slate-600 bg-opacity-60 fixed w-full h-full flex justify-center items-center z-40">
      <div className="bg-white rounded-3xl shadow-xl scale-[1.5] ">
        <div className="w-[320px] flex-col lg:p-6 p-4  flex text-neutral-900">
          <div className="flex items-center">
            <div className="grow">
              <h1 className="f-p1-sb">
                {language.orderList.bill.level[lang]} : {order.levelNumber}
              </h1>
            </div>
            <div className="text-right">
              <button
                onClick={() => {
                  handleCancel();
                }}
              >
                <img src={iconX} className="w-3 ml-auto" />
              </button>
            </div>
          </div>
          <div className="mt-4 grid grid-cols-2 gap-1">
            <div className="col-span-1">
              <div className="">
                <p className="f-p2-r">
                  {language.orderList.bill.service[lang]}
                </p>
                <p className="f-p1-m ">
                  {typeService == "dine-in"
                    ? "Dine In"
                    : typeService == "take-away"
                    ? "Take Away"
                    : "Online"}
                </p>
              </div>
              <div className="mt-2">
                <p className="f-p2-r">
                  {language.orderList.bill.customer[lang]}
                </p>
                <p className="f-p1-m line-clamp-1 w-28 overflow-hidden">
                  {order.name}
                </p>
              </div>
            </div>
            <div className="col-span-1">
              <div className="">
                <p className="f-p2-r">{language.orderList.bill.date[lang]}</p>
                <p className="f-p1-m ">-</p>
              </div>
              <div className="mt-2 ">
                <p className="f-p2-r">
                  {language.orderList.bill.orderTime[lang]}
                </p>
                <p className="f-p1-m ">-</p>
              </div>
            </div>
          </div>
          <div className="border border-dashed w-full lg:my-4 mt-4 border-neutral-600"></div>
          <div className="mt-2">
            <div className="overflow-scroll lg:h-[160px] h-[140px] lg:p-4 py-2 px-4 bg-neutral-100">
              <h4 className="lg:f-h4 font-bold">
                {language.orderList.bill.orderDetails[lang]}
              </h4>
              {cart.products.map((item: ProductCart, index) => {
                return (
                  <div className="list-group mt-2" key={`item_${index}`}>
                    <div className="grid grid-cols-12">
                      <div className="col-span-8">
                        <h5 className="lg:f-h5 f-p2-m">{item.product.name}</h5>
                      </div>
                      <div className="col-span-4">
                        <p className="f-p2-r text-right">x{item.qty}</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-12">
                      <div className="col-span-8">
                        <p className="f-p2-r">{item.note}</p>
                      </div>
                      <div className="col-span-4">
                        <p className="f-p2-r text-right">
                          {item.product.variants?.length === 0
                            ? rupiahFormat(
                                item.product.originalPrice * item.qty
                              )
                            : rupiahFormat(
                                item.product.originalPrice +
                                  (item.product?.variants?.[0]?.originalPrice ||
                                    0) *
                                    item.qty
                              )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="border border-dashed w-full lg:my-4 mb-4 border-neutral-600"></div>
          <div className="mt-2">
            <div className="flex justify-between items-center">
              <p className="f-p2-r">{language.orderList.bill.subTotal[lang]}</p>
              <p className="f-p2-r">
                {rupiahFormat(
                  cart.products.reduce((acc, item) => {
                    return (
                      acc +
                      (item.product.originalPrice +
                        (item.product.variants?.find(
                          (variant) => variant.id == item.variant
                        )?.originalPrice ?? 0)) *
                        item.qty
                    );
                  }, 0)
                )}
              </p>
            </div>
            <div className="flex justify-between items-center">
              <p className="f-p2-r">{language.orderList.bill.discount[lang]}</p>
              <p className="f-p2-r">{rupiahFormat(0)}</p>
            </div>
            <div className="flex justify-between items-center">
              <p className="f-p2-r">{language.orderList.bill.tax[lang]}</p>
              <p className="f-p2-r">
                {rupiahFormat(
                  cart.products.reduce((acc, item) => {
                    return (
                      acc +
                      (item.product.price +
                        (item.product.variants?.find(
                          (variant) => variant.id == item.variant
                        )?.price ?? 0)) *
                        item.qty
                    );
                  }, 0) -
                    cart.products.reduce((acc, item) => {
                      return (
                        acc +
                        (item.product.originalPrice +
                          (item.product.variants?.find(
                            (variant) => variant.id == item.variant
                          )?.originalPrice ?? 0)) *
                          item.qty
                      );
                    }, 0)
                )}
              </p>
            </div>
          </div>
          <div className="border border-dashed w-full lg:my-4 my-2 border-neutral-600"></div>
          <div className="mt-2 flex items-center justify-between">
            <h3 className="lg:f-h4 f-h5">
              {language.orderList.bill.total[lang]}
            </h3>
            <h3 className="lg:f-h4 f-h5">
              {rupiahFormat(
                cart.products.reduce((acc, item) => {
                  return (
                    acc +
                    (item.product.price +
                      (item.product.variants?.find(
                        (variant) => variant.id == item.variant
                      )?.price ?? 0)) *
                      item.qty
                  );
                }, 0)
              )}
            </h3>
          </div>
          <div className="mt-4">
            <button
              onClick={() => {
                callback();
              }}
              className="lg:text-[18px] text-[14px] w-full font-bold bg-main text-white hover:bg-secondary hover:text-white py-2 rounded-xl shadow-xl"
            >
              {language.orderList.bill.payment[lang]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
