import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { UserContext } from "../../../context/UserContext";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { CREATE_ORDER_CASH } from "src/graphql/mutation/CreateOrderCash";
import { MoneyAmountComponent } from "../component/MoneyAmountComponent";
import { LanguageContext } from "./../../../context/LanguageContext";
import language from "./../../../language/data.json";
import qrCodeQris from "./../../../assets/images/qr-code-qris.jpeg";
import { ButtonComponent } from "./../component/ButtonComponent";
import { OperatorContext } from "./../../../context/OperatorContext";

export function PaymentSection({
  tableNumber,
  action,
  typeService,
  cart,
  handleCancel,
  handlePrint,
}) {
  const { lang } = useContext(LanguageContext);
  const { user } = useContext(UserContext);
  const { operator } = useContext(OperatorContext);

  const [showQRCodeQris, setShowQRCodeQris] = useState(null);

  const [dataOrder, setDataOrder] = useState(null);
  const [time, setTime] = useState(200);

  const [buttonclick, setButtonClick] = useState("");
  const [alreadyClicked, setAlreadyClicked] = useState(false);

  const [
    createOrderCash,
    {
      loading: loadingCreateOrderCash,
      error: errorCreateOrderCash,
      data: dataCreateOrderCash,
    },
  ] = useMutation(CREATE_ORDER_CASH);

  async function handlePaymentCash({ moneyAmount, typePayment = "" }) {
    const tempOrder = cart.map((item) => {
      return { productId: item.id, quantity: item.amount, notes: item.notes };
    });

    const res = await createOrderCash({
      variables: {
        input: {
          orderInput: tempOrder,
          orderType:
            typeService == "dine-in"
              ? "DINE_IN"
              : typeService == "take-away"
              ? "TAKE_OUT"
              : "DINE_IN",
          tableNumber: tableNumber,
          customerPhone: user?.phone || null,
        },
      },
    });

    if (loadingCreateOrderCash == false && errorCreateOrderCash == undefined) {
      await handlePrint({
        orderId: res.data.createCashOrderCustomer.orderCustomer.id,
        typePayment: typePayment,
        moneyAmount: moneyAmount,
      });

      action(res.data.createCashOrderCustomer.orderCustomer);
    } else {
      return false;
    }
  }

  return (
    <div className="bg-slate-600 bg-opacity-60 fixed w-full h-full flex justify-center items-center z-40">
      <div className="bg-transparent scale-[1.75]">
        {buttonclick == "cash" ? (
          <MoneyAmountComponent
            callback={async (moneyAmount) => {
              const subTotal = cart.reduce(
                (temp, x) =>
                  x.buy == true ? temp + x.originalPrice * x.amount : temp,
                0,
                0
              );

              const tax = subTotal * 0.1;
              const total = subTotal + tax;

              if (moneyAmount >= total)
                await handlePaymentCash({ moneyAmount, typePayment: "CASH" });
            }}
            handleCancel={() => {
              setButtonClick(false);
            }}
          />
        ) : null}

        <div
          className={`h-full w-[450px] flex flex-col items-center justify-center gap-8 scale-75 ${
            buttonclick != "" ? "hidden" : "block"
          }`}
        >
          {operator.userRole == "manager" ? (
            <button
              onClick={async () => {
                setButtonClick("qris");
                setShowQRCodeQris(true);
              }}
              className={`lg:text-[40px] text-[24px] font-bold hover:bg-secondary hover:text-white w-full py-6 rounded-2xl shadow-xl bg-main text-white `}
            >
              {language.orderList.payment.qris.label[lang]}
            </button>
          ) : (
            <button
              onClick={async () => {
                if (alreadyClicked == true) return;
                setAlreadyClicked(true);

                const subTotal = cart.reduce(
                  (temp, x) =>
                    x.buy == true ? temp + x.originalPrice * x.amount : temp,
                  0,
                  0
                );

                const tax = subTotal * 0.1;
                const total = subTotal + tax;

                await handlePaymentCash({
                  moneyAmount: total,
                  typePayment: "CASH QRIS",
                });
              }}
              className={`lg:text-[40px] text-[24px] relative flex justify-center font-bold hover:bg-secondary hover:text-white w-full py-6 rounded-2xl shadow-xl bg-main text-white `}
            >
              {language.orderList.payment.qris.label[lang]}

              <span className="text-xs text-white absolute bottom-2">
                *Petugas akan datang ke meja untuk memindai QR Code
              </span>
            </button>
          )}

          <button
            onClick={async () => {
              setButtonClick("cash");
            }}
            className={`lg:text-[40px] text-[24px] font-bold hover:bg-secondary hover:text-white w-full py-6 rounded-2xl shadow-xl bg-main text-white `}
          >
            {language.orderList.payment.cash.label[lang]}
          </button>
        </div>

        {showQRCodeQris ? (
          <div className="bg-white shadow-xl flex flex-col justify-center items-center rounded-lg px-1 py-5 w-[300px] mx-auto">
            <img src={qrCodeQris} className="w-full" />
            <div className="px-2 w-full mt-4 flex gap-2">
              <ButtonComponent
                title={language.orderList.payment.qris.cancel[lang]}
                handle={() => {
                  setShowQRCodeQris(false);
                  setTime(200);
                  handleCancel();
                }}
              />
              <ButtonComponent
                title={language.orderList.payment.qris.done[lang]}
                type="active"
                handle={async () => {
                  if (alreadyClicked == true) return;
                  setAlreadyClicked(true);

                  const subTotal = cart.reduce(
                    (temp, x) =>
                      x.buy == true ? temp + x.originalPrice * x.amount : temp,
                    0,
                    0
                  );

                  const tax = subTotal * 0.1;
                  const total = subTotal + tax;

                  await handlePaymentCash({
                    moneyAmount: total,
                    typePayment: "CASH QRIS",
                  });
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
