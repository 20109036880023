export function MenuComponent(props) {
  return (
    <div
      key={props.id}
      className="relative w-full flex justify-center items-center"
    >
      <h4
        className="relative z-10 lg:text-[32px] text-[20px] font-semibold line-clamp-1 whitespace-nowrap"
        onClick={() => {
          props.handleClick(props.id);
        }}
      >
        {props.name}
      </h4>

      {props.active ? (
        <div className="w-[10px] h-full absolute left-6 flex justify-center">
          <div className="w-full h-[80%] bg-main rounded-full"></div>
        </div>
      ) : null}
    </div>
  );
}
