import Papa from "papaparse";

export async function fetchAndParseCSV(url) {
  try {
    const response = await fetch(url);
    const text = await response.text();
    const results = await Papa.parse(text);

    return results.data;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
}
