import { createContext, useState } from "react";

interface SidebarContextType {
    sidebar: boolean;
    setSidebar: (sidebar: boolean) => void;
}

export const SidebarContext = createContext<SidebarContextType>({
    sidebar: true,
    setSidebar: (sidebar: boolean) => {},
});

export const SidebarProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [sidebar, setSidebar] = useState(true);

    return (
        <SidebarContext.Provider value={{ sidebar, setSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
};