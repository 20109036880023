import { useContext, useState } from "react";
import { FiShoppingCart, FiX } from "react-icons/fi";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { LanguageContext } from "../../../context/LanguageContext";
import language from "./../../../language/data.json";
import imagePopUp from "./../../../assets/images/advertise/pop-up.jpg";

export function PopUpAdvertiseComponent({ product, callback, handleClose }) {
  const { lang } = useContext(LanguageContext);

  return (
    <div className="w-full h-full bg-slate-600 bg-opacity-60 fixed z-20 flex justify-center items-center left-0 top-0">
      <div className="lg:w-7/12 w-3/4 lg:min-h-[600px] bg-white rounded-3xl px-[50px] py-[35px] text-center flex flex-col items-center relative">
        <button
          className="absolute right-5 top-5"
          onClick={() => {
            handleClose();
          }}
        >
          <FiX size={24} />
        </button>
        <h3 className="font-bold text-[24px] w-10/12 line-clamp-3">
          Mie Santuy Cabe Sangat Pedas
        </h3>
        <p className="line-clamp-2 text-[20px]">
          Mie dengan rasa gurih yang nikmat dengan 15 cabe
        </p>

        <h4 className="text-[20px] font-bold">
          {language.menu.stock[lang]} : 1000
        </h4>

        <div className="mt-6 w-full overflow-hidden flex justify-center items-center">
          <img src={imagePopUp} className="lg:w-full w-32" />
        </div>

        <div className="mt-8">
          <h3 className="font-bold lg:text-[40px] text-[32px] text-main">
            {rupiahFormat(20000)}
          </h3>
        </div>
      </div>
    </div>
  );
}
