import { useContext, useState } from "react";
import { LanguageContext } from "./../../../context/LanguageContext";
import language from "./../../../language/data.json";

export function MoneyAmountComponent({ handleCancel, callback }) {
  const { lang } = useContext(LanguageContext);
  
  const [moneyAmount, setMoneyAmount] = useState();
  const [alreadyClicked, setAlreadyClicked] = useState(false);

  return (
    <div className="rounded-3xl shadow-x bg-white w-[350px]">
      <div className="flex-col text-center py-8 px-8 flex">
        <h2 className="lg:text-[20px] text-[16px] font-bold mb-2">
          {language.orderList.moneyAmountQuestion[lang]}
        </h2>
        <div className="flex justify-center my-4">
          <input
            className="w-full rounded-lg bg-white outline-none border border-slate-200 p-3 lg:text-[20px] text-[16px] text-center"
            placeholder={language.orderList.moneyAmountPlaceholder[lang]}
            type="number"
            onChange={(e) => {
              setMoneyAmount(e.target.value);
            }}
            value={moneyAmount}
          />
        </div>

        <div className="w-full flex gap-5 mt-2">
          <button
            onClick={() => {
              handleCancel();
            }}
            className="lg:text-[20px] text-[16px] w-full font-bold bg-secondary text-white hover:bg-main hover:text-white py-3 rounded-2xl shadow-xl"
          >
            {language.orderList.moneyAmountCancel[lang]}
          </button>
          <button
            onClick={() => {
              if (moneyAmount > 0 && alreadyClicked ==  false) {
                setAlreadyClicked(true);
                callback(moneyAmount);
              }
            }}
            className="lg:text-[20px] text-[16px] w-full font-bold bg-main text-white hover:bg-secondary hover:text-white py-3 rounded-2xl shadow-xl"
          >
            {language.orderList.moneyAmountConfirm[lang]}
          </button>
        </div>
      </div>
    </div>
  );
}
