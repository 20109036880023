import { useState } from "react";
import { ButtonComponent } from "./../../../components/button/button-component";

export function MemberSection({ handleCancel, callback }) {
  const [memberNumber, setMemberNumber] = useState("");

  return (
    <div className="bg-slate-600 bg-opacity-60 fixed w-full h-full flex justify-center items-center">
      <div className="rounded-3xl shadow-x bg-white">
        <div className="flex-col text-center py-8 px-8 flex">
          <h3 className="f-h3 mb-2">Masukkan ID Member anda</h3>
          <div className="flex justify-center my-4">
            <input
              className="w-full rounded-lg bg-white outline-none border border-slate-200 p-3 f-h4 text-center"
              placeholder="08xxxxxxxxxx"
              type="number"
              onChange={(e) => {
                setMemberNumber(e.target.value);
              }}
              value={memberNumber}
            />
          </div>

          <div className="w-full flex gap-5 mt-2">
            <button
              onClick={() => {
                callback(null);
              }}
              className="f-h5 w-full font-bold bg-slate-600 text-white hover:bg-primary-main py-3 rounded-2xl shadow-xl"
            >
              Not Member
            </button>
            <button
              onClick={() => {
                if (
                  memberNumber == "1" ||
                  memberNumber == "2" ||
                  memberNumber == "3" ||
                  memberNumber == "4" ||
                  memberNumber == "5" ||
                  memberNumber == "6" ||
                  memberNumber == "7" ||
                  memberNumber == "8" ||
                  memberNumber == "9"
                )
                  return;

                if (memberNumber >= 1) {
                  const phoneUtil =
                    require("google-libphonenumber").PhoneNumberUtil.getInstance();

                  const number = phoneUtil.parseAndKeepRawInput(
                    memberNumber,
                    "ID"
                  );

                  if (phoneUtil.isValidNumber(number)) {
                    callback(memberNumber);
                  } else {
                    alert(
                      "Invalid phone number, please input your phone number correctly"
                    );
                  }
                }
              }}
              className="f-h5 w-full font-bold bg-primary-main text-white hover:bg-primary-surface py-3 rounded-2xl shadow-xl"
            >
              Lanjutkan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
