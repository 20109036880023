import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { MembersServices } from "src/service/MembersServices";
import { InputDefault } from "./component/input-default";
import { InputImage } from "./component/input-image";

export function MemberAddPage() {
  const navigate = useNavigate();

  const membersServices = new MembersServices();

  const [member, setMember] = useState({
    name: "",
    phone: "",
    password: "",
    email: "",
    photo: null,
    national_id_number: "",
  });

  const [preview, setPreview] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMember((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setMember((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    setPreview(URL.createObjectURL(files[0]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await membersServices.registration({ ...member });

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      toast.success("Success add member");
      navigate("/member");
    }
  };

  return (
    <div className="col-span-12">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <h4 className="f-h4 text-center">Add Member</h4>
            <br />
            <div className="mt-0">
              <InputDefault
                label="Fullname"
                name="name"
                value={member.name}
                handleChange={handleChange}
                type="text"
                required={true}
                placeholder="John Doe"
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Email"
                name="email"
                value={member.email}
                handleChange={handleChange}
                type="email"
                required={true}
                placeholder="example@email.com"
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Password"
                name="password"
                value={member.password}
                handleChange={handleChange}
                type="password"
                required={true}
                placeholder="********"
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="Phone"
                name="phone"
                value={member.phone}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
            <div className="mt-2">
              <InputDefault
                label="National ID Number"
                name="national_id_number"
                value={member.national_id_number}
                handleChange={handleChange}
                type="text"
                required={true}
              />
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6 bg-white shadow-lg py-8 px-6 rounded-lg h-fit">
            <div className="mt-2">
              <InputImage
                label="Photo"
                name="photo"
                value={member.photo}
                preview={preview}
                handleChange={handleFileChange}
                required={true}
              />
            </div>

            <div className="mt-4 flex gap-4">
              <button
                className="grow py-2 px-4 bg-neutral-1000"
                color="neutral"
                onClick={() => {
                  navigate("/member");
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                className="bg-main py-2 px-4 rounded-full text-white grow"
                color="neutral"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
