import {aes_gcm_decrypt, aes_gcm_encrypt} from "crypto-aes-gcm";

export class SecretService {
     static #SECRET_KEY = process.env.REACT_APP_SECRET_KEY || 'd1sm0r3_53cr3t_k3y';

     static async safeURLJSONEncode(data) {
        const stringJSON = JSON.stringify(data);
        const encrypted = await aes_gcm_encrypt(stringJSON, SecretService.#SECRET_KEY)

        return encodeURIComponent(encrypted);
     }

    static async safeURLJSONDecode(data) {
        const decrypted = await aes_gcm_decrypt(decodeURIComponent(data), SecretService.#SECRET_KEY)
        return JSON.parse(decrypted);
    }
}