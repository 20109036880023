import { MdCardMembership } from "react-icons/md";
import { InputDefault } from "../member/component/input-default";
import { useState } from "react";
import { MembersServices } from "src/service/MembersServices";
import { FiX } from "react-icons/fi";
import { LoadingComponent } from "../../components/loading/loading-component";

export function CheckMembership() {
  const membersServices = new MembersServices();

  const [number, setNumber] = useState("");
  const [member, setMember] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    const res = await membersServices.getMemberCard({ id: number });

    if (res) {
      setMember({
        name: res.name,
        phone: res.phone,
        email: res.email,
        profile_pic: res.profile_pic,
        card_number: res.card_number,
        expired_at: res.expired_at,
        status: new Date(res.expired_at) > new Date() ? "Active" : "Expired",
      });
    }

    setLoading(false);
  }

  return (
    <div className="body-product grow overflow-scroll px-10 w-full pb-10 flex flex-col justify-center relative">
      {loading ? (
        <div>
          <LoadingComponent />
        </div>
      ) : member ? (
        <div className="flex flex-col items-center bg-white shadow-xl rounded-2xl p-10 lg:w-3/4 w-full mx-auto relative">
          <button
            onClick={() => {
              setMember(null);
            }}
            className="absolute right-5 top-5"
          >
            <FiX size={56} />
          </button>
          <h3 className="text-[48px] font-bold text-center">Membership</h3>
          <p className="text-[32px] font-semibold text-center">
            Membership Information
          </p>
          <div className="flex flex-col items-center mt-10 w-full">
            <img
              src={member.profile_pic}
              alt="profile"
              className="rounded-full w-40 h-40"
            />
            <div
              className={`mt-5 text-[24px] font-semibold mx-auto text-white py-2 px-8 rounded-full ${
                member.status == "Active" ? "bg-main" : "bg-red-500"
              }`}
            >
              {member.status}
            </div>
            <div className="flex flex-col items-start w-full">
              <div className="grid grid-cols-4 gap-4 mt-5 w-full">
                <p className="col-span-1 text-[24px] font-semibold">Name</p>
                <p className="col-span-3 text-[24px]">: {member.name}</p>
              </div>
              <div className="grid grid-cols-4 gap-4 mt-5 w-full">
                <p className="col-span-1 text-[24px] font-semibold">Phone</p>
                <p className="col-span-3 text-[24px]">: {member.phone}</p>
              </div>
              <div className="grid grid-cols-4 gap-4 mt-5 w-full">
                <p className="col-span-1 text-[24px] font-semibold">Email</p>
                <p className="col-span-3 text-[24px]">: {member.email}</p>
              </div>
              <div className="grid grid-cols-4 gap-4 mt-5 w-full">
                <p className="col-span-1 text-[24px] font-semibold">Card</p>
                <p className="col-span-3 text-[24px]">: {member.card_number}</p>
              </div>
              <div className="grid grid-cols-4 gap-4 mt-5 w-full">
                <p className="col-span-1 text-[24px] font-semibold">Exp Date</p>
                <p className="col-span-3 text-[24px]">
                  :{" "}
                  {new Date(member.expired_at).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <h3 className="text-[48px] font-bold text-center">Scan HERE</h3>
          <p className="text-[32px] font-semibold text-center">
            Scan Card to check your membership
          </p>
          <MdCardMembership
            size={120}
            className="lg:scale-150 text-main mt-20"
          />

          <form className="flex items-end mt-20 gap-4" onSubmit={handleSubmit}>
            <InputDefault
              label="Card Number"
              name="card_number"
              value={number}
              handleChange={(e) => setNumber(e.target.value)}
              type="text"
              required={true}
              placeholder="1234 5678 9012 3456"
              autoFocus={true}
            />
            <button
              type="submit"
              className="py-2 px-5 bg-main rounded-full text-white"
            >
              Check
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
