import React from "react";

import { CartProvider } from "./CartContext";
import { CombineComponents } from "./CombineComponents";
import { LanguageProvider } from "./LanguageContext";
import { LoadingProvider } from "./LoadingContext";
import { OrderProvider } from "./OrderContext";
import { SidebarProvider } from "./SidebarContext";
import { UserProvider } from "./UserContext";

const providers = [
  UserProvider,
  LoadingProvider,
  CartProvider,
  SidebarProvider,
  LanguageProvider,
  OrderProvider,
];
export const AppContextProvider = CombineComponents(...providers);
