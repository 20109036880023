import { gql } from "@apollo/client";

export const GET_SUBMENUS = gql`
  query Submenus($menuId: ID!) {
    submenus(menuId: $menuId) {
      id
      name
    }
  }
`;
