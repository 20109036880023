export function NavbarComponent(props) {
  return (
    <div
      className={`w-full py-10 items-center text-center relative flex gap-4 justify-center ${
        props.type == "dark" ? "text-slate-900" : "text-white"
      }`}
    >
      {props.leftIcon != null ? (
        <button
          onClick={props.handleLeft}
          className="flex justify-end items-center absolute left-10 lg:p-4"
        >
          <props.leftIcon size={48} className="lg:scale-150" />
        </button>
      ) : (
        <></>
      )}
      <h1 className="font-semibold lg:text-[60px] text-[40px]">{props.title}</h1>
      {props.rightIcon != null ? (
        <button
          onClick={props.handleRight}
          className="flex justify-start items-center absolute right-10 lg:p-4"
        >
          <props.rightIcon size={48} className="lg:scale-150" />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}

export function NavbarSecondaryComponent(props) {
  return (
    <div
      className={`w-full py-8 items-center text-center relative flex gap-4 justify-center ${
        props.type == "dark" ? "text-slate-900" : "text-white"
      }`}
    >
      {props.leftIcon != null ? (
        <button
          onClick={props.handleLeft}
          className="flex justify-end items-center absolute left-10"
        >
          <props.leftIcon size={45} className="lg:scale-150" />
        </button>
      ) : (
        <></>
      )}
      <h1 className="font-semibold lg:text-[48px] text-[32px]">{props.title}</h1>
      {props.rightIcon != null ? (
        <button
          onClick={props.handleRight}
          className="flex justify-start items-center absolute right-10"
        >
          <props.rightIcon size={45} className="lg:scale-150" />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
