import { useContext, useState } from "react";
import { LanguageContext } from "./../../../context/LanguageContext";
import language from "./../../../language/data.json";

export function ChooseLevelNumberSection({ handleCancel, callback }) {
  const { lang } = useContext(LanguageContext);
  const [levelNumber, setLevelNumber] = useState("");

  return (
    <div className="bg-slate-600 bg-opacity-60 fixed w-full h-full flex justify-center items-center">
      <div className="rounded-3xl shadow-x bg-white">
        <div className="flex-col text-center py-8 px-8 flex">
          <h2 className="lg:text-[36px] text-[28px] font-bold mb-2">
            {language.orderList.level.question[lang]}
          </h2>
          <div className="flex justify-center my-4">
            <input
              className="w-full rounded-lg bg-white outline-none border border-slate-200 p-3 text-[24px] text-center"
              placeholder={language.orderList.level.placeholder[lang]}
              type="number"
              min={1}
              max={5}
              onChange={(e) => {
                setLevelNumber(e.target.value);
              }}
              value={levelNumber}
            />
          </div>

          <div className="w-full flex gap-5 mt-2">
            <button
              onClick={() => {
                handleCancel();
              }}
              className="lg:text-[24px] text-[20px] w-full font-bold bg-secondary text-white hover:bg-main hover:text-white py-3 rounded-2xl shadow-xl"
            >
              {language.orderList.level.cancel[lang]}
            </button>
            <button
              onClick={() => {
                if (levelNumber >= 1 && levelNumber <= 5) callback(levelNumber);
              }}
              className="lg:text-[24px] text-[20px] w-full font-bold bg-main text-white hover:bg-secondary hover:text-white py-3 rounded-2xl shadow-xl"
            >
              {language.orderList.level.next[lang]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
