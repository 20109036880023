import { createContext, useState } from "react";

export const JSONContext = createContext();

export const JSONProvider = (props) => {
  const [JSON, setJSON] = useState("");

  return (
    <JSONContext.Provider value={{ JSON, setJSON }}>
      {props.children}
    </JSONContext.Provider>
  );
};
