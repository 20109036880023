import { Menu } from "src/types/init";

export function MenuComponent({
  menu,
  handleClick,
  active,
}: {
  menu: Menu;
  handleClick: (id: string) => void;
  active: boolean;
}) {
  return (
    <div
      key={menu.id}
      className="relative w-full flex justify-center items-center"
    >
      <h4
        className="relative z-10 lg:text-[32px] text-[20px] font-semibold line-clamp-1 whitespace-nowrap"
        onClick={() => {
          handleClick(menu.id);
        }}
      >
        {menu.name}
      </h4>

      {active ? (
        <div className="w-[10px] h-full absolute left-6 flex justify-center">
          <div className="w-full h-[80%] bg-main rounded-full"></div>
        </div>
      ) : null}
    </div>
  );
}
