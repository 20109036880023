import moment from "moment";
import 'moment/locale/id';

export class TimeServices {
    #momentTime;

    constructor() {
        this.#momentTime = moment();
    }

    setLocale(locale) {
        if (!locale) {
            locale = 'id'
        }
        this.#momentTime.locale(locale);
        return this;
    }

    setMomentDate(date) {
        this.#momentTime = moment(date);
        return this;
    }

    getMomentDate() {
        return this.#momentTime;
    }

    static parse(date) {
        return (new TimeServices()).setMomentDate(date);
    }

    getISO8601() {
        return this.#momentTime.format();
    }
}