import { gql } from "@apollo/client";

export const GET_TENANTS = gql`
  {
    tenants {
      id
      menus {
        name
        isPromotion
        id
        displayPic
      }
      name
    }
  }
`;
