import { FiChevronLeft, FiHome } from "react-icons/fi";
import logo from "./../../assets/images/logo.png";
import { Route, Routes, useNavigate } from "react-router-dom";
import { MemberListPage } from "./list";
import { MemberAddPage } from "./add";
import { MemberDetailPage } from "./detail";

export function MemberPage() {
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen grid grid-cols-12">
      <div className={`flex flex-col items-center h-screen col-span-12`}>
        <div className="heading w-full shadow-lg px-10 lg:pb-10 pb-3">
          <div className="flex justify-between lg:mt-20 mt-4 w-full">
            <button
              className="lg:p-4"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FiChevronLeft size={48} className="lg:scale-150" />
            </button>
            <img src={logo} className="lg:w-[180px] w-[100px]" />
            <button
              className="lg:p-4"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <FiHome size={48} className="lg:scale-150" />
            </button>
          </div>
        </div>

        <div className="w-full p-8 grid grid-cols-12">
          <Routes>
            <Route path="/" element={<MemberListPage />} />
            <Route path="/add" element={<MemberAddPage />} />
            <Route path="/detail/:id" element={<MemberDetailPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
