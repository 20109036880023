import axios from "axios";
import { REACT_APP_GRAPHQL_API_URL } from "src/configs/url";
import { handleAxiosError } from "src/service/errors";
import { headers } from "src/service/config";

const GET_PRODUCTS = `
  query Products($submenuId: ID!) {
    products(submenuId: $submenuId) {
      id
      name
      originalPrice
      price
      stock
      description
      displayPic
      variants {
        description
        displayPic
        id
        name
        originalPrice
        price
        productId
        stock
      }
      stock
      state
      additionalData
    }
  }
`;

export class ProductGraphServices {
  async gets({ submenuId }: { submenuId: string }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: GET_PRODUCTS, variables: { submenuId } },
        {
          headers: headers,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
