export function InputDefault({
  label,
  name,
  value,
  handleChange,
  type = "text",
  required = false,
  size = "md",
  placeholder = "",
  autoFocus = false,
}) {
  return (
    <div className="flex flex-col">
      <label className="f-p1-r mb-1 text-neutral-1000">{label}</label>
      <input
        placeholder={placeholder}
        className="w-full rounded-lg py-2 px-5 border border-slate-300 focus:outline-none focus:border-main focus:ring-1 focus:ring-main"
        onChange={handleChange}
        size={size}
        value={value}
        type={type}
        name={name}
        required={required}
        autoFocus={autoFocus}
      />
    </div>
  );
}
