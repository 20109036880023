import { useNavigate, useParams } from "react-router-dom";
import typeServiceImage from "./../../assets/images/enjoy.jpg";
import iconStar from "./../../assets/icon/star.svg";
import iconStarFill from "./../../assets/icon/star-fill.svg";
import { useContext, useEffect, useState } from "react";
import { NavbarComponent } from "../../components/navbar/navbar-component";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { CREATE_RATING } from "src/graphql/mutation/CreateRating";
import { useMutation } from "@apollo/client";
import { NotionComponent } from "../../components/notion";
import { LanguageContext } from "../../context/LanguageContext";
import language from "./../../language/data.json";

export function RatingPage() {
  const { idOrderCustomer } = useParams();
  const navigate = useNavigate();
  const { lang } = useContext(LanguageContext);

  const [
    createRating,
    {
      loading: loadingCreateRating,
      error: errorCreateRating,
      data: dataCreateRating,
    },
  ] = useMutation(CREATE_RATING);

  const [star, setStar] = useState(0);
  const [buttonClick, setButtonClick] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
    }, 20000);
  }, []);

  const addRating = async (value) => {
    try {
      const res = await createRating({
        variables: {
          input: {
            orderCustomerId: idOrderCustomer,
            value: value,
          },
        },
      });

      if (loadingCreateRating == false && errorCreateRating == undefined) {
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-full relative overflow-hidden">
      <div className="fixed h-full w-full z-0 overflow-hidden">
        <img src={typeServiceImage} alt="" className="w-full h-full" />
      </div>
      <div className="fixed h-full w-full bg-gray-300 bg-opacity-70 z-0"></div>

      <div className="absolute z-10 w-full">
        <NavbarComponent
          title={language.rating.navbar[lang]}
          type="dark"
          rightIcon={FiHome}
          handleRight={() => {
            window.location.href = "/";
          }}
        />
      </div>
      <div className="relative h-full w-full flex flex-col items-center justify-center">
        <div className="w-10/12 flex flex-col justify-center relative">
          <div className="flex flex-col justify-center items-center w-full">
            <h2 className="lg:text-[80px] text-[48px] text-center text-black font-bold mb-8">
              {language.rating.text1[lang]} <br /> {language.rating.text2[lang]}
            </h2>
            <h2 className="text-[40px] text-center text-gray-700 font-bold">
              {language.rating.text3[lang]}
            </h2>
          </div>
          <div className="flex mt-12 justify-center">
            <div className="flex w-3/4 justify-center gap-5">
              {Array.from(Array(5).keys()).map((item, index) => {
                return (
                  <button
                    key={index}
                    onClick={async () => {
                      if (!buttonClick) {
                        setButtonClick(true);
                        setStar(index + 1);

                        const res = await addRating(index + 1);
                      }
                    }}
                  >
                    <img
                      src={iconStar}
                      className={`w-48 ${star <= index ? "block" : "hidden"}`}
                    />
                    <img
                      src={iconStarFill}
                      className={`w-48 ${
                        star >= index + 1 ? "block" : "hidden"
                      }`}
                    />
                  </button>
                );
              })}
            </div>
          </div>

          <div className="bg-white bg-opacity-60 mt-20 text-center rounded-lg w-2/3 mx-auto p-5">
            <p className="font-bold text-[24px]">
              {language.rating.information[lang]}
            </p>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 w-full">
        <NotionComponent />
      </div>
    </div>
  );
}
