import { useNavigate } from "react-router-dom";
import typeServiceImage from "./../../assets/images/enjoy.jpg";
import takeAway from "./../../assets/icon/take-away.svg";
import dineIn from "./../../assets/icon/dine-in.svg";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { NavbarComponent } from "../../components/navbar/navbar-component";
import { NotionComponent } from "../../components/notion";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import language from "../../language/data.json";

export function TypeServicePage() {
  const navigate = useNavigate();
  const { lang } = useContext(LanguageContext);

  return (
    <div className="h-full relative overflow-hidden">
      <div className="fixed h-full w-full z-0 overflow-hidden">
        <img src={typeServiceImage} alt="" className="w-full h-full" />
      </div>
      <div className="fixed h-full w-full bg-gray-300 bg-opacity-70 z-0"></div>

      <div className="absolute z-10 w-full">
        <NavbarComponent
          title={language.typeService.navbar[lang]}
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="relative h-full w-full flex flex-col items-center justify-center">
        <div className="w-10/12 flex flex-col justify-center relative">
          <div className="flex justify-center  w-full mt-40">
            <h2 className="lg:text-[72px] text-[40px] text-center text-black font-bold">
              {language.typeService.question1[lang]}
              <hr></hr> {language.typeService.question2[lang]}
            </h2>
          </div>
          <div className="grid grid-cols-12 mt-20 gap-20">
            <div className="col-span-6">
              <button
                onClick={() => {
                  navigate("/dine-in/menu");
                }}
                className={`flex flex-col gap-5 text-[55px] rounded-[36px] font-bold justify-center items-center py-10 px-10 w-full lg:h-[370px] hover:bg-white bg-main`}
              >
                <img src={dineIn} className="w-2/3" />
              </button>
              <h2 className="lg:text-[56px] text-[32px] font-bold text-black text-center mt-5 uppercase">
                {language.typeService.answer1[lang]}
              </h2>
            </div>
            <div className="col-span-6">
              <button
                onClick={() => {
                  navigate("/take-away/menu");
                }}
                className={`flex flex-col gap-5 text-[55px] rounded-[36px] font-bold justify-center items-center py-10 px-10 w-full lg:h-[370px] hover:bg-white bg-main`}
              >
                <img src={takeAway} className="w-2/3" />
              </button>
              <h2 className="lg:text-[56px] text-[32px] font-bold text-black text-center mt-5 uppercase">
                {language.typeService.answer2[lang]}
              </h2>
            </div>

            <div className="col-span-6">
              <button
                onClick={() => {
                  navigate("/online/menu");
                }}
                className={`flex flex-col gap-5 text-[55px] rounded-[36px] font-bold justify-center items-center py-10 px-10 w-full lg:h-[370px] hover:bg-white bg-main`}
              >
                <img src={dineIn} className="w-2/3" />
              </button>
              <h2 className="lg:text-[56px] text-[32px] font-bold text-black text-center mt-5 uppercase">
                Grab
              </h2>
            </div>
          </div>
          <div className="lg:h-72 h-20"></div>
        </div>
      </div>

      <div className="absolute bottom-0 w-full">
        <NotionComponent />
      </div>
    </div>
  );
}
