import { gql } from "@apollo/client";

export const CREATE_STRIPE_TRANSACTION = gql`
  mutation CreateStripeTransaction($input: CreateStripePaymentInput!) {
    createStripeTransaction(input: $input) {
      qrCode
      transaction {
        id
        url
      }
    }
  }
`;
