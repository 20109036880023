import { gql } from "@apollo/client";

export const CREATE_ORDER = gql`
  mutation CreateOrderCustomer($input: CreateOrderCustomerInput!) {
    createOrderCustomer(input: $input) {
      orderCustomer {
        id
        orderType
        totalAmount
        totalQty
      }
    }
  }
`;
