import React, { useContext, useEffect, useState } from "react";
import { FiMinus, FiPlus, FiTrash } from "react-icons/fi";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { LanguageContext } from "../../../context/LanguageContext";
import language from "../../../language/data.json";
import { ProductCart } from "src/types/init";

export function ProductComponent({
  productCart,
  handleBuy,
  removeProduct,
}: {
  productCart: ProductCart;
  handleBuy: any;
  removeProduct: any;
}) {
  const { lang } = useContext(LanguageContext);

  const [buy, setBuy] = useState(false);

  useEffect(() => {
    setBuy(productCart.buy);
  }, [productCart.buy]);

  return (
    <div className="flex px-[75px] py-2 items-center justify-between gap-5 bg-white">
      <div className="flex items-center lg:gap-10 gap-5">
        <div className="flex items-center">
          <input
            id="default-checkbox"
            type="checkbox"
            onChange={() => {
              handleBuy(!buy);
              setBuy(!buy);
            }}
            checked={buy}
            className="w-6 h-6 text-main bg-gray-100 border-gray-300 rounded focus:ring-main dark:focus:ring-main dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>
        <div
          className={`h-fit lg:w-[150px] py-2 px-2 rounded-xl flex flex-col items-center overflow-hidden`}
        >
          <div className="lg:w-[130px] lg:h-[130px] w-20 h-20 overflow-hidden flex justify-center items-center rounded-xl">
            <img src={productCart.product.displayPic} className="w-full" />
          </div>
        </div>

        <div>
          <h3 className="font-medium lg:text-[24px] text-[20px] line-clamp-2">
            {productCart.product.name} ( X{productCart.qty} )
          </h3>
          <div className="bg-gray-200 rounded-lg py-2 px-3 mt-2 w-fit">
            <h4 className="text-secondary text-lg font-bold">
              {language.orderList.notes[lang]} :{" "}
              <span className="font-medium">{productCart.note}</span>
            </h4>
          </div>
          <h2 className="font-bold lg:text-[32px] text-[24px] text-main mt-1">
            {productCart.product.variants?.length === 0
              ? rupiahFormat(
                  productCart.product.originalPrice * productCart.qty
                )
              : rupiahFormat(
                  productCart.product.originalPrice +
                    (productCart.product?.variants?.find(
                      (item) => item.id == productCart.variant
                    )?.originalPrice || 0) *
                      productCart.qty
                )}
          </h2>
        </div>
      </div>
      <div className="">
        <button
          onClick={removeProduct}
          className="bg-main text-white rounded-lg px-4 py-4"
        >
          <FiTrash size={24} />
        </button>
      </div>
    </div>
  );
}
