import axios from "axios";
import { REACT_APP_GRAPHQL_API_URL } from "src/configs/url";
import { handleAxiosError } from "src/service/errors";
import { headers } from "src/service/config";

const GET_SUBMENUS = `
  query Submenus($menuId: ID!) {
    submenus(menuId: $menuId) {
      id
      name
    }
  }
`;

export class SubmenuGraphServices {
  async gets({ menuId }: { menuId: string }) {
    try {
      const response = await axios.post(
        `${REACT_APP_GRAPHQL_API_URL}`,
        { query: GET_SUBMENUS, variables: { menuId } },
        {
          headers: headers,
        }
      );
      return response.data.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
