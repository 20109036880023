import { useContext, useState } from "react";
import home from "./../../assets/images/home-resize.jpg";
import axios from "axios";
import { AuthServices } from "src/service/AuthServices";
import { LoadingContext } from "../../context/LoadingContext.tsx";
import toast from "react-hot-toast";
import { JWTPayload } from "src/utils/JWTPayload";
import { LanguageContext } from "../../context/LanguageContext";
import language from "./../../language/data.json";

export function AuthPage() {
  const authServices = new AuthServices();

  const { loading, setLoading } = useContext(LoadingContext);
  const { lang } = useContext(LanguageContext);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const res = await authServices.SignIn(form);

    setLoading(false);

    if (res?.message != null) {
      toast.error(res.message[0]);
    } else if (res) {
      const payload = JWTPayload(res.token);

      if (payload) {
        toast.success("Success sign in");
        document.cookie = `token=${res.token}`;
        window.location.href = "/";
      }
    }
  };

  return (
    <div className="h-full relative overflow-hidden flex flex-col justify-center">
      <div className="absolute h-full w-full z-0">
        <img src={home} alt="" className="w-full h-full" />
      </div>

      <div className="relative flex justify-center">
        <form
          onSubmit={handleSubmit}
          className="bg-white bg-opacity-70 shadow-md rounded-3xl px-8 pt-6 pb-8 mb-4 flex flex-col w-[500px]"
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-xl font-bold mb-2"
              for="email"
            >
              {language.auth.email.label[lang]}
            </label>
            <input
              className="shadow appearance-none text-xl border rounded-3xl w-full py-3 px-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              placeholder={language.auth.email.placeholder[lang]}
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-xl font-bold mb-2"
              for="password"
            >
              {language.auth.password.label[lang]}
            </label>
            <input
              className="shadow appearance-none border text-xl rounded-3xl w-full py-3 px-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              name="password"
              onChange={handleChange}
              placeholder={language.auth.password.placeholder[lang]}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-xl text-white w-full font-bold py-4 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {language.auth.login[lang]}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
