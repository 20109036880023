import { useContext, useEffect, useState } from "react";
import { ErrorServices } from "src/service/ErrorServices";
import { UserContext } from "../../../context/UserContext";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { MoneyAmountComponent } from "../component/money-amount-component";
import { LanguageContext } from "../../../context/LanguageContext";
import language from "../../../language/data.json";
import { Cart, Order } from "src/types/init";
import { TransactionGraphServices } from "src/graphql/services/TransactionGraphServices";
import { OrderGraphServices } from "src/graphql/services/OrderGraphServices";
import toast from "react-hot-toast";
import { OrderContext } from "../../../context/OrderContext";

export function PaymentSection({
  order,
  action,
  cart,
  handleCancel,
  handlePrint,
}: {
  order: Order;
  cart: Cart;
  handleCancel: any;
  handlePrint: any;
  action: any;
}) {
  const transactionGraphServices = new TransactionGraphServices();
  const orderGraphServices = new OrderGraphServices();
  const errorServices = new ErrorServices();

  const { user } = useContext(UserContext);
  const { lang } = useContext(LanguageContext);

  const [qrCodeQris, setQrCodeQris] = useState("");
  const [time, setTime] = useState(200);

  const [buttonClick, setButtonClick] = useState("");

  async function handlePaymentCash({ moneyAmount }: { moneyAmount: number }) {
    if (
      moneyAmount <
      cart.products.reduce(
        (acc, product) =>
          acc +
          product.product.price +
          (product.product.variants?.find(
            (variant) => variant.id == product.variant
          )?.price || 0) *
            product.qty,
        0
      )
    ) {
      toast.error("Money Amount is not enough");
      setButtonClick("");
      return;
    }

    if (order.orderInput.length == 0) {
      toast.error("Cart is empty");
      setButtonClick("");
      return;
    }

    const res = await orderGraphServices.createOrderCash({
      orderCustomer: {
        orderInput: order.orderInput.map((orderInput) => {
          return {
            productId: orderInput.product.id,
            productVariantId: orderInput.variant?.id,
            quantity: orderInput.quantity,
            notes: orderInput.notes,
          };
        }),
        tableNumber: order.tableNumber,
        orderType: order.orderType,
        customerPhone: order.customerPhone,
        memberCardNumber: order.memberCardNumber,
      },
    });

    toast.success("Order Success");

    await handlePrint({
      resOrderId: res.createCashOrderCustomer.orderCustomer.id,
      cart: cart,
      typePayment: "CASH",
      moneyAmount: moneyAmount,
      order: order,
      user: user,
      customer: order.name,
    });

    action(res.createCashOrderCustomer.orderCustomer);
  }

  async function handlePaymentQris() {
    if (order.orderInput.length == 0) {
      toast.error("Cart is empty");
      setButtonClick("");
      return;
    }

    const resOrder = await orderGraphServices.createOrder({
      orderCustomer: {
        orderInput: order.orderInput.map((orderInput) => {
          return {
            productId: orderInput.product.id,
            productVariantId: orderInput.variant?.id,
            quantity: orderInput.quantity,
            notes: orderInput.notes,
          };
        }),
        tableNumber: order.tableNumber,
        orderType: order.orderType,
        customerPhone: order.customerPhone,
        memberCardNumber: order.memberCardNumber,
      },
    });

    const resTransaction = await transactionGraphServices.createQrisTransaction(
      {
        orderCustomerId: resOrder.createOrderCustomer.orderCustomer.id,
      }
    );

    if (resTransaction.createQrisTransaction.transaction.url == null) {
      toast.error("Failed to create QRIS transaction");
      setButtonClick("");
    }

    setQrCodeQris(resTransaction.createQrisTransaction.transaction.url);

    handleWaitingPayment({
      orderId: resOrder.createOrderCustomer.orderCustomer.id,
      transactionId: resTransaction.createQrisTransaction.transaction.id,
    });
  }

  async function handleCheckPayment({
    orderId,
    transactionId,
  }: {
    orderId: string;
    transactionId: string;
  }) {
    const res = await transactionGraphServices.isTransactionSettled({
      id: transactionId,
    });

    if (res?.isTransactionSettled == true) {
      toast.success("Order Success");

      await handlePrint({
        resOrderId: orderId,
        cart: cart,
        typePayment: "QRIS",
        moneyAmount: cart.products.reduce((acc, item) => {
          return (
            acc +
            (item.product.price +
              (item.product.variants?.find(
                (variant) => variant.id == item.variant
              )?.price ?? 0)) *
              item.qty
          );
        }, 0),
        order: order,
        user: user,
        customer: order.name,
      });

      action(orderId);
    } else {
      setTimeout(() => {
        handleCheckPayment({
          orderId,
          transactionId,
        });
      }, 1000);
    }
  }

  function handleWaitingPayment({
    orderId,
    transactionId,
  }: {
    orderId: string;
    transactionId: string;
  }) {
    handleCheckPayment({ orderId, transactionId });
  }

  return (
    <div className="bg-slate-600 bg-opacity-60 fixed w-full h-full flex justify-center items-center z-40">
      <div className="bg-transparent scale-[1.75]">
        {buttonClick == "cash" ? (
          <MoneyAmountComponent
            callback={async (moneyAmount) => {
              const total = cart.products.reduce((acc, item) => {
                return (
                  acc +
                  (item.product.price +
                    (item.product.variants?.find(
                      (variant) => variant.id == item.variant
                    )?.price ?? 0)) *
                    item.qty
                );
              }, 0);

              if (moneyAmount >= total) {
                await handlePaymentCash({ moneyAmount });
              } else {
                alert(
                  "Your money is not enough, at least " + rupiahFormat(total)
                );
              }
            }}
            handleCancel={() => {
              setButtonClick("");
            }}
          />
        ) : null}
        <div
          className={`h-full w-[450px] flex flex-col items-center justify-center gap-8 scale-75 ${
            buttonClick != "" ? "hidden" : "block"
          }`}
        >
          <button
            onClick={async () => {
              setButtonClick("qris");
              const res = await handlePaymentQris();

              // setTimeout(() => {
              //   window.location.reload();
              // }, 200000);
            }}
            className={`lg:text-[40px] text-[24px] font-bold hover:bg-secondary hover:text-white w-full py-6 rounded-2xl shadow-xl bg-main text-white `}
          >
            {language.orderList.payment.qris.label[lang]}
          </button>
          <button
            onClick={async () => {
              setButtonClick("cash");
            }}
            className={`lg:text-[40px] text-[24px] font-bold hover:bg-secondary hover:text-white w-full py-6 rounded-2xl shadow-xl bg-main text-white `}
          >
            {language.orderList.payment.cash.label[lang]}
          </button>
        </div>
        {qrCodeQris ? (
          <div className="bg-white shadow-xl flex flex-col justify-center items-center rounded-lg px-1 py-5 w-[250px] mx-auto">
            <h2 className="lg:f-h2 f-h3 text-center text-main">
              {language.orderList.payment.qris.label[lang]}
            </h2>
            <p className="text-center mt-2 text-[12px]">
              {language.orderList.payment.qris.title[lang]}{" "}
            </p>
            <p className="text-orange-700 font-semibold mt-2 lg:text-[16px] text-[14px]">
              {language.orderList.payment.qris.expired[lang]}{" "}
              {Math.floor(time / 60) < 10
                ? "0" + Math.floor(time / 60)
                : Math.floor(time / 60)}
              {" : "}
              {time % 60 < 10 ? "0" + (time % 60) : time % 60}
            </p>
            <img src={qrCodeQris} className="w-full" />
            <h4 className="f-h4 text-center text-main">
              {rupiahFormat(
                cart.products.reduce((acc, item) => {
                  return (
                    acc +
                    (item.product.price +
                      (item.product.variants?.find(
                        (variant) => variant.id == item.variant
                      )?.price ?? 0)) *
                      item.qty
                  );
                }, 0)
              )}
            </h4>

            <div className="px-2 w-full mt-4">
              <button
                onClick={() => {
                  setQrCodeQris("");
                  setTime(200);
                  handleCancel();
                }}
                className="lg:text-[18px] text-[14px] w-full font-bold bg-gray-400 text-white hover:bg-secondary hover:text-white py-2 rounded-xl shadow-xl"
              >
                {language.orderList.payment.qris.cancel[lang]}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
