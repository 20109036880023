import { useContext, useEffect, useRef, useState } from "react";
import { AppContextProvider } from "./context/AppContextProvider";
import AppDemoDismoreRouter from "./routers";
import Draggable from "react-draggable";
import { FaRegUser } from "react-icons/fa";
import { socket } from "src/socket";
import SimplePeer from "simple-peer";
import { REACT_APP_API_KEY } from "src/configs/url";
import { RiCustomerService2Line } from "react-icons/ri";
import { HelpStatusContext } from "./context/HelpStatusContext";

export default function AppDemoDismore() {
  return (
    <AppContextProvider>
      <div className="h-screen w-full relative overflow-hidden">
        <GlobalComponent />
        <AppDemoDismoreRouter />
      </div>
    </AppContextProvider>
  );
}

function GlobalComponent() {
  const { helpStatus, setHelpStatus } = useContext(HelpStatusContext);

  return (
    <div className="absolute z-50 w-full h-10 flex justify-end">
      {helpStatus == "call" ? <CallerComponent /> : null}
    </div>
  );
}

export function HelpComponent({ onClick }) {
  return (
    <div className="">
      <button
        onClick={() => {
          onClick();
        }}
        className="bg-main p-4 rounded-xl flex justify-center items-center"
      >
        <RiCustomerService2Line size="48" className="text-white" />
      </button>
    </div>
  );
}

function CallerComponent() {
  const [callAccepted, setCallAccepted] = useState(false);
  const receiverVideo = useRef();
  const connectionRef = useRef();

  useEffect(() => {
    socket.on("call-accepted", (signal) => {
      setCallAccepted(true);
      if (connectionRef.current) {
        connectionRef.current.signal(signal);
      }
    });
    callUser(REACT_APP_API_KEY);
  }, []);

  const callUser = async (id) => {
    console.log(id);

    const streamDisplay = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: true,
    });

    const peer = new SimplePeer({
      initiator: true,
      trickle: false,
      stream: streamDisplay,
      config: {
        iceServers: [
          { urls: "stun:dismore-vc.alfian-py.site:3478" },
          {
            urls: "turn:dismore-vc.alfian-py.site:3478",
            username: "testname",
            credential: "testpass",
          },
        ],},
    });

    peer.on("signal", (data) => {
      socket.emit("call", {
        csApp: id,
        signalData: data,
        coreApp: socket.id,
        branch: "DEMO APP - Surabaya",
      });
    });

    peer.on("stream", (stream) => {
      if (receiverVideo.current) {
        receiverVideo.current.srcObject = stream;
      }
    });

    connectionRef.current = peer;
  };

  return (
      <Draggable defaultPosition={{x: 0, y:0 }}>
        <div className="h-96 p-4 w-96 bg-black flex justify-center items-center">
          {callAccepted ? (
            <video playsInline muted ref={receiverVideo} autoPlay />
          ) : (
            <FaRegUser size="42" className="text-white" />
          )}
        </div>
      </Draggable>
  );
}
