import image404 from "./../../assets/images/404.png";
import { ButtonComponent } from "../../components/button/button-component";

export function Page404() {
  return (
    <div className="w-full h-screen flex items-center">
      <div className="bg-white w-full h-fit flex flex-col items-center text-center">
        <div className="w-9/12">
          <div className="w-full mx-auto flex justify-center">
            <img src={image404} alt="" />
          </div>
          <h2 className="font-semibold text-[64px] mt-20">
            404 Page Not Found
          </h2>
          <p className="text-[30px] mt-4 italic">
            Please register yourself through our cashier
          </p>

          <div className="w-full mt-5 flex justify-center">
            <div className="w-96">
              <ButtonComponent
                  fonts="text-[50px]"
                  title="Refresh App"
                  type="button"
                  handle={() => {
                    window.location.href = "/";
                  }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
