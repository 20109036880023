import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./../pages/home";
import { TypeServicePage } from "./../pages/type-service";
import { MenuPage } from "./../pages/menu";
import { OrderListPage } from "./../pages/order-list";
import { RatingPage } from "./../pages/rating";
import { Page404 } from "./../pages/error/404";
import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { AuthPage } from "../pages/auth";
import { JWTPayload } from "src/utils/JWTPayload";
import { LoadingComponent } from "../components/loading/loading-component";
import { cookies } from "src/service/config";
import { UserContext } from "../context/UserContext";
import { User } from "src/types/init";

export default function AppBonBonKahveRouter() {
  const { lang, setLang } = useContext(LanguageContext);
  const { user, initUser, logout } = useContext(UserContext);

  const [load, setLoad] = useState(true);

  useEffect(() => {
    fetch();

    const langTemp = localStorage.getItem("lang");
    if (langTemp) {
      setLang(lang);
    }
  }, []);

  async function fetch() {
    const payload = JWTPayload(cookies.token ?? "");
    const nextExp = (payload.exp + 43200 * 2 * 7) * 1000;

    if (nextExp / 1000 < new Date().getTime() / 1000) {
      document.cookie = "token=; Max-Age=0";

      logout();
      window.location.href = "/";
    }

    if (payload) {
      const temp = {
        name: payload.name,
        email: payload.email,
        userRole: payload.user_role,
      } as User;
      initUser(temp);
    }
    setLoad(false);
  }

  if (load == true) {
    return <LoadingComponent />;
  } else if (user.email != "" || user.userRole != "") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/type-service" element={<TypeServicePage />} />

          <Route path="/:typeService/menu" element={<MenuPage />} />

          <Route path="/:typeService/order-list" element={<OrderListPage />} />

          <Route path="/rating/:idOrderCustomer" element={<RatingPage />} />

          <Route path="/404" element={<Page404 />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/auth" element={<AuthPage />} />
          <Route path="*" element={<AuthPage />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
