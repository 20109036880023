import { AppContextProvider } from "./context/AppContextProvider";
import AppTebuTerusPsreboRouter from "./routers";

export default function AppTebuTerusPsrebo() {
  return (
    <AppContextProvider>
      <div className="h-screen w-full relative">
        <AppTebuTerusPsreboRouter />
      </div>
    </AppContextProvider>
  );
}
