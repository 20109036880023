import { AppContextProvider } from "./context/AppContextProvider";
import AppMagicBilliardRouter from "./routers";

export default function AppDemoDismore() {
  return (
    <AppContextProvider>
      <div className="h-screen w-full relative">
        <AppMagicBilliardRouter />
      </div>
    </AppContextProvider>
  );
}
