import { useParams } from "react-router-dom";
import logoBlack from "./../../assets/images/logo-black.png";

export function PrintPage() {
  const queryParameters = new URLSearchParams(window.location.search);

  const json = JSON.parse(queryParameters.get("json"));

  return (
    <div className="flex-col py-4 px-4 flex text-neutral-900">
      <div className="text-center">
        <img alt="logo" src={logoBlack} className="w-[300px] mx-auto" />
        <h3 className="f-h3 mt-0">45 CoffeeShop</h3>
        <p className="f-p2-r mt-2">
          Perum. Graha Asri Sukodono No.1 blok B, Pekorungan, Pekarungan, Kec.
          Sukodono, Kabupaten Sidoarjo,
          <br />
          Jawa Timur 61258
          <br />
          IG. @45_coffeeshop, Telp. 085269977555
        </p>
      </div>
      <div className="flex items-center mt-2">
        <div className="grow">
          <h1 className="f-p1-sb">
            Lantai {json.levelNumber} / {json.tableNumber}
          </h1>
        </div>
        <div className="text-right">
          <p className="f-p1-r">Tipe Pembayaran</p>
          <p className="f-p1-sb">{json.typePayment}</p>
        </div>
      </div>
      <div className="mt-0">
        <p className="f-p1-r">
          Invoice: <span className="f-p1-sb">{json.idOrderCustomer}</span>
        </p>
      </div>
      <div className="mt-2 flex gap-1">
        <div className="grow">
          <div className="">
            <p className="f-p1-r">Layanan</p>
            <p className="f-p1-sb">{json.typeService}</p>
          </div>
          <div className="mt-2">
            <p className="f-p1-r">Pelanggan</p>
            <p className="line-clamp-1 f-p1-sb w-28 overflow-hidden">
              {json.user.name}
            </p>
          </div>
        </div>
        <div className="grow">
          <div className="">
            <p className="f-p1-r">Waktu Pemesanan</p>
            <p className="f-p1-sb">{json.orderTime}</p>
          </div>
          <div className="mt-2 ">
            <p className="f-p1-r">Mesin Pemesanan</p>
            <p className="f-p1-sb">{json.machine}</p>
          </div>
        </div>
      </div>
      <div className="border border-dashed w-full my-2 border-neutral-600"></div>
      <div className="mt-0">
        <div className="p-0">
          <h4 className="f-p1-sb">Detail Pemesanan</h4>
          {json.orders.map((x) => {
            return (
              <div className="list-group mt-1">
                <div className="flex">
                  <div className="w-3/4">
                    <h5 className="f-h5">{x.name}</h5>
                  </div>
                  <div className="grow text-right">
                    <p className="f-p1-r">x{x.amount}</p>
                  </div>
                </div>
                <div className="flex">
                  <div className="">
                    <p className="italic f-p1-r">{x.notes}</p>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <p className="f-p1-r">{x.originalPrice}</p>
                  </div>
                  <div className="grow text-right">
                    <p className="f-p1-sb">{x.totalPrice}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="border border-dashed w-full my-2 border-neutral-600"></div>
      <div className="mt-0">
        <div className="flex justify-between items-center">
          <p className="f-p1-r">Sub Total</p>
          <p className="f-p1-sb">{json.subTotal}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="f-p1-r">Diskon</p>
          <p className="f-p1-sb">{json.discount}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="f-p1-r">Pajak 10%</p>
          <p className="f-p1-sb">{json.tax}</p>
        </div>
      </div>
      <div className="border border-dashed w-full my-2 border-neutral-600"></div>
      <div className="mt-0 flex items-center justify-between">
        <h3 className="f-p1-r">Total</h3>
        <h3 className="f-p1-sb">{json.total}</h3>
      </div>
      <div className="mt-0 flex items-center justify-between">
        <h3 className="f-p1-r">Total Bayar</h3>
        <h3 className="f-p1-sb">{json.moneyAmount}</h3>
      </div>
      <div className="mt-0 flex items-center justify-between">
        <h3 className="f-p1-r">Kembali</h3>
        <h3 className="f-p1-sb">{json.returnAmount}</h3>
      </div>
      <div className="text-center mt-2">
        <h3 className="f-h3 mt-2">Terima Kasih</h3>
      </div>
    </div>
  );
}
