import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";

export function PopUpFailComponent({ myRef }) {
  return (
    <dialog ref={myRef}>
      <div className="w-full h-full bg-slate-600 bg-opacity-60 fixed z-10 flex justify-center items-center left-0 top-0">
        <div className="w-[550px] h-fit bg-white rounded-3xl px-[50px] py-[35px] text-center flex flex-col items-center relative mx-auto my-auto">
          <h3 className="font-bold text-[28px] w-10/12 line-clamp-2">
            Produk sudah ada pada kerangjang
          </h3>
          <div className="mt-5 text-red-600">
            <FiX size={72} />
          </div>
        </div>
      </div>
    </dialog>
  );
}
